<template>
    <b-container fluid class="border mt-4 w-100 mx-0 px-3">
        <b-row class="my-4">
            <b-col class="text-left">
                <b-button variant="outline-dark shadow" v-b-modal.new_promotion_modal>
                    <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                    Kreiraj promociju
                </b-button>
            </b-col>
        </b-row>
        <b-row>
             <b-col >
                <b-table
                   :items="promotions"
                   :fields="fields"
                   hover
                   :busy="loading"
                   responsive
                   :tbody-tr-class="rowClass"
                   class="shadow"
                   head-variant="dark"
                   :striped="true"
                >
                    <template v-slot:cell(start_date)="data">
                        {{ data.item.start_date | moment('DD.MM.YYYY') }}
                    </template>
                    <template v-slot:cell(end_date)="data">
                        {{ data.item.end_date | moment('DD.MM.YYYY') }}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            @click="$router.push(`/promotion/${data.item.id}`)"
                            size="sm"
                            variant="outline-dark mr-2">
                            <i class="fa fa-cogs" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.edit_promotion_modal
                            @click="setEditData(data.item)"
                            size="sm"
                            variant="outline-info mr-2">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.delete_promotion_modal
                            @click="promotionId = data.item.id"
                            size="sm"
                            variant="outline-danger">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
             </b-col>
        </b-row>

        <b-modal
            id="new_promotion_modal"
            ref="new_promotion_modal"
            title="Kreiraj novu promociju"
            @hide="form = {}"
            centered
            size="lg"
        >
            <b-container> 
                <b-form>
                    <b-form-group>
                        <span>Naziv promocije</span>
                        <b-row>
                            <b-col cols="12">
                                <b-form-input
                                    :class="!$v.form.title.required ? 'border border-danger' : 'border border-dark'"
                                    v-model="form.title" ></b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <span>Trajanje promocije</span>
                        <b-row>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="form.start_date"
                                    id="10"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    overlay
                                    format="YYYY-MM-DD"
                                    label="Datum od"
                                    :class="!$v.form.start_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="form.end_date"
                                    only-date
                                    id="11"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    :class="!$v.form.end_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    overlay
                                    format="YYYY-MM-DD"
                                    label="Datum do"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('new_promotion_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.form.$invalid"
                    @click="createPromotion(form)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Kreiraj
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="edit_promotion_modal"
            ref="edit_promotion_modal"
            @hide="form = {}; fetchPromotions();"
            title="Izmeni promociju"
            centered
            size="lg"
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <span>Naziv promocije</span>
                        <b-row>
                            <b-col cols="12">
                                <b-form-input
                                    :class="!$v.form.title.required ? 'border border-danger' : 'border border-dark'"
                                    v-model="form.title" ></b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <span>Trajanje promocije</span>
                        <b-row>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="form.start_date"
                                    id="1"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    overlay
                                    format="YYYY-MM-DD"
                                    label="Datum od"
                                    :class="!$v.form.start_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="form.end_date"
                                    id="2"
                                    color="#787c80"
                                    button-color="#787c80"
                                    :class="!$v.form.end_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    overlay
                                    format="YYYY-MM-DD"
                                    only-date
                                    label="Datum do"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_promotion_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.form.$invalid"
                    @click="editPromotion(form)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>
        <confirm-delete-modal
            :modal-name="'delete_promotion_modal'"
            :description="'promociju'"
            @confirm="deletePromotion(promotionId)"/>
    </b-container>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters, mapMutations} from "vuex";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
export default {
    name: "Promotions",
    data() {
        return {
            form: {
                title: '',
                start_date: null,
                end_date: null,
            },
            promotionId: null,
            fields: [
                { key: "title", label: "Naslov", class: "text-left" },
                { key: "start_date", label: "Datum početka", class: "d-none d-sm-none d-md-table-cell d-lg-table-cell"},
                { key: "end_date", label: "Datum isteka", class: "d-none d-sm-none d-md-table-cell d-lg-table-cell"},
                { key: "actions", label: "Akcije", class: 'text-right' },
            ],
        }
    },
    validationGroup: ['form'],
    validations: {
        form: {
            title: {
                required
            },
            start_date: {
                required
            },
            end_date: {
                required
            }
        }
    },
    components: {ConfirmDeleteModal},
    mounted() {
        this.fetchPromotions();
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("promotions", {
            promotions: "getPromotions"
        })
    },
    methods: {
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapActions("promotions", {
            fetchPromotions: "fetchPromotions",
            createPromotion: "createPromotion",
            editPromotion: "editPromotion",
            deletePromotion: "deletePromotion"
        }),
        setEditData(data) {
            this.form = data;
        },
        rowClass(item, type) {
            if (item && type === 'row') {
                var start_date = item.start_date;
                var end_date = item.end_date;
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;
                if (today <= end_date && today >= start_date ) {
                    return 'table-success';
                } else {
                    return 'table-danger';
                }
            }
        },
    }

}
</script>

