<template>
    <b-container fluid class="border mt-4 w-100 mx-0 px-3">
        <b-row class="my-4">
            <b-col cols="12" class="d-flex ml-auto">
                <div class="mr-auto d-flex border border-secondary rounded-lg pt-1 shadow">
                    <span class="pl-2 py-1">Prikaži neregistrovane korisnike:</span>
                    <label class="switch ml-3">
                        <input
                            v-model="showUnregistered"
                            type="checkbox">
                        <span class="slider"></span>
                    </label>
                </div>
				<div v-if="!showUnregistered" class="mr-auto d-flex border border-secondary rounded-lg pt-1 shadow">
                    <span class="pl-2 py-1">Povezan na sistem:</span>
                    <label class="switch ml-3">
                        <input
                            v-model="showConnectedToSystem"
                            type="checkbox">
                        <span class="slider"></span>
                    </label>
                </div>
				<!-- <div v-if="!showUnregistered" class="mr-auto d-flex border border-secondary rounded-lg pt-1 shadow">
                    <span class="pl-2 py-1">Prihvaćena pretplata:</span>
                    <label class="switch ml-3">
                        <input
                            v-model="showAcceptedSubscription"
                            type="checkbox">
                        <span class="slider"></span>
                    </label>
                </div> -->
                <div class="ml-auto w-25 d-flex border border-secondary rounded-lg shadow">
                    <b-input-group class="d-flex w-100">
                        <i class="fa fa-search m-auto px-2" aria-hidden="true"></i>
                        <b-form-input v-model="searchString" class="ml-auto border-0 outline-dark" placeholder="Pretraži korisnike"></b-form-input>
                    </b-input-group>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    :items="users"
                    :fields="userFields"
                    hover
                    :busy="loading"
                    class="shadow"
                    responsive
                    head-variant="dark"
                    :striped="true"
                >
                    <template v-slot:cell(verified)="data">
                        <label class="switch shadow">
                            <input
                                v-model="data.item.verified"
                                @change="setEditData(data.item);
                                editUser(editData);"
                                type="checkbox">
                            <span class="slider"></span>
                        </label>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            v-b-modal.edit_user_modal
                            class="mr-3"
                            @click="setEditData(data.item)"
                            size="sm"
                            variant="outline-success shadow">
                            <i class="fa fa-users-cog" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.remove_user_modal
                            size="sm"
                            @click="userId = data.item.id"
                            variant="outline-danger shadow">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="users && users.length < 1">
            <b-col class="my-5">
                <b-alert show variant="secondary" class="w-50 mx-auto">Nema rezultata za pretragu koju ste uneli.</b-alert>
            </b-col>
        </b-row>
        <b-row class="align-items-center justify-content-between mt-auto pb-4 shadow-sm">
            <b-col cols="6" class="d-flex justify-content-start align-items-center">
                <b-pagination
                    v-model="filters.page"
                    :total-rows="userCount"
                    :per-page="filters.per_page"
                    class="my-0 shadow"
                ></b-pagination>
                <h6 class="p-0 m-0 ml-2">Ukupno : {{ userCount }}</h6>
            </b-col>
            <b-col cols="3">
                <b-form-group
                    label-cols="0"
                    label-cols-sm="5"
                    label-cols-md="6"
                    label-class="text-nowrap"
                    label="Po stranici: "
                    class="mb-0 text-right"
                >
                    <b-form-select
                        v-model="filters.per_page"
                        :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-modal
            id="edit_user_modal"
            size="lg"
            centered
            @hide="fetchUsers({searchString: this.searchString, showUnregistered: this.showUnregistered, filters: this.filters}); clearEditData();"
            title="Izmeni korisnički nalog"
        >
            <template #modal-title>
                <div>
                    <b-row>
                        <div class="pl-2">Izmenite korisnicki nalog: <code class="ml-2">&nbsp;{{ editData.first_name }} {{ editData.last_name }}</code></div>
                    </b-row>
                    <b-row>
                        <span class="mr-3 pl-2">Verifikovan: </span>
                        <label class="switch shadow">
                            <input v-model="editData.verified" type="checkbox">
                            <span class="slider"></span>
                        </label>
                    </b-row>
                </div>
            </template>
            <b-col order="2" order-md="1" cols="12" md="12" class="mb-3">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group label="Ime" label-for="first_name">
                                <b-form-input
                                    id="first_name"
                                    v-model="editData.first_name"
                                    class="shadow-sm"
                                    placeholder="Ime"
                                    :class="!$v.editData.first_name.required ? 'border-danger' : ''"
                                    name="first_name"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Prezime" label-for="last_name">
                                <b-form-input
                                    id="last_name"
                                    placeholder="Prezime"
                                    class="shadow-sm"
                                    v-model="editData.last_name"
                                    :class="!$v.editData.last_name.required ? 'border-danger' : ''"
                                    name="last_name"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Email adresa" label-for="email">
                                <b-form-input
                                    id="email"
                                    v-model="editData.email"
                                    class="shadow-sm"
                                    placeholder="Email"
                                    :class="!$v.editData.email.required || !$v.form.email.email ? 'border-danger' : ''"
                                    email
                                    name="email"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Telefon" label-for="phone">
                                <b-form-input
                                    id="phone"
                                    v-model="editData.phone"
                                    class="shadow-sm"
                                    placeholder="Telefon"
                                    :class="!$v.editData.phone.required ? 'border-danger' : ''"
                                    name="phone"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group label="Broj kartice" label-for="card_id">
                                <input
                                    v-model="editData.card_id"
                                    placeholder="Broj kartice"
                                    class="w-100 rounded py-1 px-2 border border-primary padding-card-id shadow-sm"
                                    type="number">
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group label="JMBG" label-for="jmbg" class="w-100">
                                <input
                                    v-model="editData.jmbg"
                                    placeholder="JMBG"
                                    class="w-100 rounded py-1 border border-primary px-2 shadow-sm"
                                    type="number">
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mb-3">
                            <span>Rola</span>
                            <multiselect
                                v-model="editData.role"
                                :options="roles"
                                select-label="Izaberi"
                                selected-label="Izabrano"
                                :class="!$v.editData.role.required ? 'border-danger shadow-sm' : 'shadow-sm'"
                                deselect-label="Izbaci"
                                :limit="3"
                                track-by="key"
                                label="name"
                                :searchable="true"
                                placeholder="Izaberi rolu">
                            </multiselect>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="border-bottom m-3 pb-1 shadow"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="mt-1">
                            <b-form-group label="Nova lozinka" label-for="password">
                                <b-form-input
                                    id="password"
                                    class="shadow-sm"
                                    name="password"
                                    placeholder="Nova lozinka"
                                    type="text"
                                    v-model="form.password"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="mt-1">
                            <b-form-group label="Ponovi lozinku" label-for="re_password">
                                <b-form-input
                                    id="re_password"
                                    type="text"
                                    class="shadow-sm"
                                    placeholder="Ponovi lozinku"
                                    v-model="form.re_password"
                                    name="re_password"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <small class="text-danger d-flex justify-content-center m-0 p-0" v-if="form.password !== form.re_password">Lozinke se ne poklapaju</small>
                </b-form>
            </b-col>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_user_modal')" size="sm" variant="outline-danger shadowt">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    :disabled="$v.editData.$invalid || form.password !== form.re_password"
                    @click="editUser(editData)"
                    variant="outline-success shadow">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Sačuvaj
                </b-button>
            </template>
        </b-modal>
        <confirm-delete-modal
            :modal-name="'remove_user_modal'"
            :description="'korisnika'"
            @confirm="deleteUser({
                userId: userId,
                searchString: searchString,
                showUnregistered: showUnregistered,
				showConnectedToSystem: showConnectedToSystem,
                filters: filters })"/>
    </b-container>
</template>

<script>
import {required, email, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {PaginationFilters} from "../mixins/pagination";
import { sha512 } from 'js-sha512';
import {roles} from '../constants/roles'
import _ from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
    name: "Users",
    data() {
        return {
            searchString: '',
            userId: null,
            roles: [],
            showUnregistered: false,
			showConnectedToSystem: false,
			// showAcceptedSubscription: false,
            form: {
                password: '',
                re_password: '',
            },
            editData: {

            },
            userFields: [
                { key: "first_name", label: "Ime", class: "text-left" },
                { key: "last_name", label: "Prezime", class: "text-left" },
                { key: "email", label: "Email", class: "text-left d-none d-sm-none d-md-table-cell d-lg-table-cell" },
                { key: "phone", label: "Telefon", class: "text-left d-none d-sm-none d-md-none d-lg-table-cell" },
                { key: "verified", label: "Verifikovan nalog", class: "text-center d-none d-sm-none d-md-table-cell d-lg-table-cell" },
                { key: "actions", label: "Akcije", class: "text-right", thClass: "pr-4" },
            ]
        }
    },
    validationGroup: ['form', 'editData'],
    validations: {
        form: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            email: {
                required,
                email
            },
            phone: {
                required,
            },
            password: {
                required,
                minLength: minLength(5)
            },
            re_password: {
                required,
                minLength: minLength(5)
            }
        },
        editData: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            email: {
                required,
                email
            },
            phone: {
                required,
            },
            role: {
                required,
            },
            jmbg: {
                minLength: minLength(13),
                maxLength: maxLength(13)
            }
        },
    },
    mixins: [PaginationFilters],
    components: {ConfirmDeleteModal},
    mounted() {
        this.fetchUsers({
            searchString: this.searchString,
            showUnregistered: this.showUnregistered,
			showConnectedToSystem: this.showConnectedToSystem,
			// showAcceptedSubscription: this.showAcceptedSubscription,
            filters: this.filters
        });
        this.roles = roles;
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("users", {
            users: "getUsers",
            userCount: "getCount"
        })
    },
    methods: {
        ...mapActions("users", {
            fetchUsers: "fetchUsers",
            editUserData: "editUser",
            deleteUser: "deleteUser"
        }),
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        setEditData(data) {
            this.editData = data;
            this.roles.forEach((item, i) => {
                if (data.role === item.key) {
                    this.editData.role = {}
                    this.editData.role = item;
                }
            })
        },
        async editUser(data) {
            if (this.form.password !== '' && this.form.password !== '') {
                data.password = sha512(this.form.password);
            }
            if (data.jmbg === '') {
                data.jmbg = null;
            }
            if (data.card_id === '') {
                data.card_id = null;
            }
            const apiData = {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                card_id: data.card_id,
                jmbg: data.jmbg,
                role: data.role.key,
                verified: data.verified,
                password: data.password
            };
            await this.editUserData({
                userId: data.id,
                apiData: apiData,
                searchString: this.searchString,
                showUnregistered: this.showUnregistered,
                filters: this.filters
            }) .then((resp) => {
                    this.editData = {};
                    this.form.password = '';
                    this.form.re_password = '';
                })
        },
        clearEditData() {
            this.editData = {};
            this.form.password = '';
            this.form.re_password = '';
        },
    },
    watch: {
        showUnregistered(val) {
			if (val) {
				this.fetchUsers({
                	searchString: this.searchString,
                	showUnregistered: val,
                	filters: this.filters
            	});
			} else {
				this.fetchUsers({
                	searchString: this.searchString,
                	showUnregistered: val,
					showConnectedToSystem: this.showConnectedToSystem,
					// showAcceptedSubscription: this.showAcceptedSubscription,
                	filters: this.filters
            	});
			}
        },
		showConnectedToSystem(val) {
            this.fetchUsers({
                searchString: this.searchString,
                showUnregistered: this.showUnregistered,
				showConnectedToSystem: this.showConnectedToSystem,
				// showAcceptedSubscription: this.showAcceptedSubscription,
                filters: this.filters
            });
        },
		// showAcceptedSubscription(val) {
        //     this.fetchUsers({
        //         searchString: this.searchString,
        //         showUnregistered: this.showUnregistered,
		// 		showConnectedToSystem: this.showConnectedToSystem,
		// 		showAcceptedSubscription: this.showAcceptedSubscription,
        //         filters: this.filters
        //     });
        // },
        'editData.jmbg'(val) {
            if (val && val.length > 13) {
                this.editData.jmbg = this.editData.jmbg.substr(0, 13);
            }
        },
        'editData.card_id'(val) {
            if (val && val.length > 10) {
                this.editData.card_id = this.editData.card_id.substr(0, 10);
            }
        },
        searchString: _.debounce(function() {
            this.fetchUsers({
                searchString: this.searchString,
                showUnregistered: this.showUnregistered,
				showConnectedToSystem: this.showConnectedToSystem,
				// showAcceptedSubscription: this.showAcceptedSubscription,
                filters: this.filters
            });
        }, 400),
        filters: {
            async handler() {
                await this.fetchUsers({
                    searchString: this.searchString,
                    showUnregistered: this.showUnregistered,
					showConnectedToSystem: this.showConnectedToSystem,
					// showAcceptedSubscription: this.showAcceptedSubscription,
                    filters: this.filters
                });
            },
            deep: true
        },
        deep: true
    }
}
</script>