<template>
    <b-container fluid class="login-layout">
        <div class="img mb-3"></div>
        <b-card class="login-card shadow-lg" bodyClass="pb-1">
            <b-form class="flex flex-column justify-content-stretch align-items-center">
                <b-row>
                    <b-col class="mb-3">
                        <login-logo></login-logo>
                    </b-col>
                </b-row>
                <b-form-input
                    id="email"
                    type="text"
                    required
                    v-model="form.email"
                    autofocus
                    placeholder="Email Address"
                    class="mb-3"
                ></b-form-input>
                <b-form-input
                    id="password"
                    type="password"
                    v-model="form.password"
                    required
                    placeholder="Password"
                    class="mb-3"
                ></b-form-input>
                <div class="d-none">
                    <span class="text-center text-danger">
                        Invalid credentials
                    </span>
                </div>
                <b-button type="submit" @click.prevent="login" variant="danger" block>
                    Sign in
                </b-button>
            </b-form>
            <p class="m-0 mt-5 w-100 text-center">
                &copy;{{ todaysYear }} Fitness & Gym Kočović
            </p>
        </b-card>
    </b-container>
</template>

<script>
import LoginLogo from "@/icons/LoginLogo";
import { sha512 } from 'js-sha512';
import { mapActions } from "vuex";
export default {
    name: "Login",
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            todaysYear: null,
        }
    },
    mounted() {
        var date = new Date;
        this.todaysYear = date.getFullYear();
    },
    components: {LoginLogo},
    methods: {
        ...mapActions("auth", {
            loginUser: "login"
        }),
        async login() {
            var email = this.form.email;
            var password = sha512(this.form.password);
            this.loginUser({email: email, password: password});
        },
    }
}
</script>
