export const PaginationFilters = {
  data() {
    return {
      filters: {
        page: 1,
        per_page: 10,
      },
      total: 1,
      pageOptions: [2, 5, 10, 15, 25, 50, 100]
    };
  }
};
