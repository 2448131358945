import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: require("../views/Welcome").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: '/users',
        name: 'Users',
        component: require("../views/Users").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: '/event',
        name: 'Event',
        component: require("../views/Event").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: '/blog',
        name: 'Blog',
        component: require("../views/Blog").default,
        meta: {
           requiresAuth: true
       }
    },
    // {
    //     path: '/dashboard',
    //     name: 'Dashboard',
    //     component: Dashboard,
    //     meta: {
    //        requiresAuth: true
    //    }
    // },
    {
        path: "/coaches",
        name: "Coaches",
        component: require("../views/Coaches").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/schedule",
        name: "Schedule",
        component: require("../views/Schedule").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/clubs",
        name: "Clubs",
        component: require("../views/Clubs").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/trainings",
        name: "Trainings",
        component: require("../views/Trainings").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/booking",
        name: "Booking",
        component: require("../views/RegularBooking").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/promotions",
        name: "Promotions",
        component: require("../views/Promotions").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/promotion/:id",
        name: "Promotion",
        component: require("../views/PromotionSetting").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/promotion-booking",
        name: "PromotionBooking",
        component: require("../views/PromotionBooking").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/online-trainings",
        name: "OnlineTrainings",
        component: require("../views/OnlineTrainings").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/statistic",
        name: "Statistic",
        component: require("../views/Statistic").default,
        meta: {
           requiresAuth: true
       }
    },
    {
        path: "/login",
        name: "Login",
        component: require("../views/Login").default,
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth) && !localStorage.getItem("token")) {
    next('/login')
  } else next()
});

export default router
