import http from "../config/http"
export default {
    namespaced: true,
    state: {
        weeklyReport: {},
        monthlyReport: {},
        dates: [],
        hours: {},
        weeklyDates: [],
    },
    mutations: {
        setWeeklyReport(state, data) {
          state.weeklyReport = data;
        },
        setWeeklyDates(state, data) {
          state.weeklyDates = data;
        },
        setDates(state, data) {
          state.dates = data;
        },
        setHours(state, data) {
          state.hours = data;
        },
        setMonthlyReport(state, data) {
          state.monthlyReport = data;
        },
    },
    actions: {
        async fetchWeeklyReport(context, data) {
            context.commit("setWeeklyReport", {});
            context.commit("setHours", {});
            context.commit("setDates", []);
            context.commit('loader/setLoading', true, { root: true });
            var hours = {};
            var weeklyDates = [];
            if (data.startDateWeekly) {
                const resp = await http.get(`/bo/rep/training/week/gym_room/${data.selectedRoom.id}?start_date=${data.startDateWeekly}`);
                if (resp) {
                    var dates = Object.fromEntries(Object.entries(resp.data).reverse())
                    context.commit("setWeeklyReport", dates);
                    // Object.keys(dates).forEach((key) => {
                    //     if (weeklyDates.indexOf(key) === -1) {
                    //         weeklyDates.push({date: key, day: dates[key][0].period.day});
                    //     }
                    // });
                    Object.keys(dates).forEach((key) => {
                        Object.keys(dates[key]).forEach((k) => {
                            if (!hours[dates[key][k].time]) {
                                hours[dates[key][k].time] = [];
                                hours[dates[key][k].time].push(dates[key]);
                            } else {
                                hours[dates[key][k].time].push(dates[key]);
                            }
                        });
                    });
                }
                weeklyDates.sort((a, b) => (a.date > b.date ) ? 1 : -1);
                Object.keys(hours).forEach((key) => {
                    hours[key].sort((a, b) => a.day - b.day);
                });
                context.commit("setWeeklyDates", weeklyDates);
                context.commit("setHours", hours);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
        async fetchMonthlyReport(context, data) {
                context.commit("setMonthlyReport", {});
                context.commit("setDates", []);
                context.commit('loader/setLoading', true, { root: true });
                var monthlyReport = {};
                var dates = [];
                let url = '';
                if (!data.startDate && !data.endDate) {
                   url = `/bo/rep/training/daily/gym/${data.selectedGym.id}`
                } else if (data.startDate && !data.endDate) {
                    url = `/bo/rep/training/daily/gym/${data.selectedGym.id}?start_date=${data.startDate}`
                } else if (data.startDate && data.endDate) {
                    url = `/bo/rep/training/daily/gym/${data.selectedGym.id}?start_date=${data.startDate}&end_date=${data.endDate}`
                }
                const resp = await http.get(url);
                if (resp) {
                    var rawData = resp.data;
                    Object.keys(rawData).forEach((key) => {
                        Object.keys(rawData[key]).forEach((k) => {
                            if (dates.indexOf(key) === -1) {
                                dates.push({day: key, date: k});
                            }
                        });
                    });
                    dates.sort((a, b) => (a.date > b.date ) ? 1 : -1);
                    context.commit("setDates", dates);

                    Object.keys(rawData).forEach((key) => {
                        Object.keys(rawData[key]).forEach((k) => {
                            Object.keys(rawData[key][k]).forEach((i) => {
                                // TODO: in case he needs to go back to the old
                                // if (!monthlyReport[rawData[key][k][i].period.training.name]) {
                                //     monthlyReport[rawData[key][k][i].period.training.name] = [];
                                //     monthlyReport[rawData[key][k][i].period.training.name].push(rawData[key][k][i]);
                                // } else {
                                //     monthlyReport[rawData[key][k][i].period.training.name].push(rawData[key][k][i]);
                                //     // console.log(Number(monthlyReport[rawData[key][k][i].period.training.name][key].booking_count), 'ovo');
                                // }
                                if (!monthlyReport[rawData[key][k][i].period_id]) {
                                    monthlyReport[rawData[key][k][i].period_id] = [];
                                    monthlyReport[rawData[key][k][i].period_id].name = rawData[key][k][i].name;
                                    monthlyReport[rawData[key][k][i].period_id].days = [];
                                    monthlyReport[rawData[key][k][i].period_id].days.push(rawData[key][k][i].day);
                                    monthlyReport[rawData[key][k][i].period_id].value = [];
                                    monthlyReport[rawData[key][k][i].period_id].value.push(rawData[key][k][i]);
                                } else {
                                    monthlyReport[rawData[key][k][i].period_id].days.push(rawData[key][k][i].day);
                                    monthlyReport[rawData[key][k][i].period_id].value.push(rawData[key][k][i]);
                                }
                            });
                        });
                    });
                    context.commit("setMonthlyReport", monthlyReport);
                    context.commit('loader/setLoading', false, { root: true });
                }
            },
    },
    getters: {
        getWeeklyReport(state) {
            return state.weeklyReport;
        },
        getHours(state) {
            return state.hours;
        },
        getDates(state) {
            return state.dates;
        },
        getWeeklyDates(state) {
            return state.weeklyDates;
        },
        getMonthlyReport(state) {
            return state.monthlyReport;
        },
    }
}