<template>
    <div class="welcome-wrapper background">
        <div>
            <welcome-logo class="h-25 w-25 logo mt-5 pt-5"></welcome-logo>
        </div>
    </div>
</template>

<script>
    import WelcomeLogo from "../icons/WelcomeLogo";
    export default {
        name: "Welcome",
        components: {WelcomeLogo},
    }
</script>