<template>
    <b-modal
        :id="modalName"
        :ref="modalName"
        hide-footer
        hide-header
        centered
    >
        <b-container class="p-2">
            <b-row>
                <b-col>
                    <h3>Da li ste sigurni da želite da izbrišete {{ description }}?</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right mt-3">
                    <b-button variant="outline-danger" class="mr-3 ml-auto" @click="$bvModal.hide(modalName)">
                        <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                        Otkaži
                    </b-button>
                    <b-button variant="outline-success" @click="$emit('confirm'); $bvModal.hide(modalName);">
                        <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                        <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                        Potvrdi
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "ConfirmDeleteModal",
    props: {
        modalName: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        })
    }
}
</script>