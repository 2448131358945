<template>
    <b-card class="mt-4">
        <b-row class="mb-4">
            <b-col class="text-left">
                <b-button variant="outline-dark shadow" v-b-modal.new_coach_modal>
                    <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                    Dodaj trenera
                </b-button>
            </b-col>
        </b-row>
        <b-row>
             <b-col>
                <b-table
                   :items="coaches"
                   :fields="fields"
                   hover
                   :busy="loading"
                   responsive
                   class="shadow"
                   head-variant="dark"
                   :striped="true"
                >
                    <template v-slot:cell(actions)="data">
                        <b-button
                            v-b-modal.edit_coach_modal
                            @click="set_edit_data(data.item)"
                            size="sm"
                            class="mr-3"
                            variant="outline-success shadow">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.confirm_delete_modal
                            @click="coach_id = data.item.id"
                            size="sm"
                            variant="outline-danger shadow">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
                 <p v-if="coaches.length === 0" class="mt-5">Trenutno nema trenera</p>
             </b-col>
        </b-row>

        <b-modal
            id="new_coach_modal"
            ref="new_coach_modal"
            scrollable
            title="Dodaj trenera"
            @hide="form = {}; clearImage(null);"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group label="Ime i prezime">
                        <b-form-input
                                required
                                v-model="form.name"
                                placeholder="Unesite ime i prezime trenera (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Opis [SRB]">
                         <vue-editor
                            v-model="form.description"
                            placeholder="Unesite opis trenera (obavezno)"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group label="Opis [ENG]">
                        <vue-editor
                            v-model="form.description_eng"
                            placeholder="Unesite opis trenera na engleskom jeziku (obavezno)"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group label="Slika">
                        <div v-if="image.url" class="text-center mb-3 bg-primary rounded p-3">
                            <b-img :src="image.url" v-bind="image_size" class="img-thumbnail"/>
                        </div>
                        <b-form-file
                            accept="image/*"
                            type="file"
                            browse-text="Izaberi"
                            @change="setImage"
                            placeholder="Izaberite sliku (obavezno)">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload slike su  550 x 700</small>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('new_coach_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="$v.form.$invalid || !image.image || loading"
                    @click="addNew(form)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodaj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_coach_modal"
            ref="edit_coach_modal"
            scrollable
            title="Izmeni trenera"
            centered
            @hide="edit_data = {}; clearImage(null);"
        >
            <b-container>
                <b-form>
                    <b-form-group label="Ime i prezime">
                        <b-form-input
                                required
                                v-model="edit_data.name"
                                placeholder="Unesite ime trenera"
                        ></b-form-input>
                    </b-form-group>
                     <b-form-group label="Opis [SRB]">
                         <vue-editor
                            v-model="edit_data.description"
                            placeholder="Unesite opis trenera"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group label="Opis [ENG]">
                        <vue-editor
                            v-model="edit_data.description_eng"
                            placeholder="Unesite opis trenera na engleskom jeziku"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group label="Slika">
                        <div v-if="image.url" class="text-center mb-3 bg-primary p-3 rounded">
                            <b-img :src="image.url" v-bind="image_size" class="img-thumbnail"/>
                        </div>
                        <b-form-file
                            accept="image/*"
                            type="file"
                            browse-text="Izaberi"
                            @change="setImage"
                            placeholder="Izaberite sliku">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload slike su 550 x 700</small>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_coach_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button size="sm" variant="outline-success" :disabled="loading" @click="editCoach(edit_data)">
                    <b-spinner v-if="loading" small class="mr-1"></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <confirm-delete-modal
            :modal-name="'confirm_delete_modal'"
            :description="'trenera'"
            @confirm="deleteCoach(coach_id)"/>
    </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {PaginationFilters} from "../mixins/pagination";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
    name: "Coaches",
    mixins: [PaginationFilters],
    data() {
        return {
            host: process.env.VUE_APP_API_URL,
            form: {},
            edit_data: {},
            image_size: { width: 200, height: 200 },
            coach_id: null,
            customToolbar: [["bold", "italic", "underline"]],
            fields: [
                { key: "name", label: "Ime i prezime", class: "text-left" },
                { key: "actions", label: "Akcije", class: "text-right" },
            ],
        }
    },
    components: {ConfirmDeleteModal},
    validationGroup: ['form'],
    validations: {
        form: {
            name: {
                required,
            },
            description: {
                required,
            },
            description_eng: {
                required,
            }
        },
    },
    mounted() {
        if (!this.coaches || this.coaches.length < 1) {
            this.fetchCoaches();
        }
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("upload", {
            image: "getImage_1"
        }),
        ...mapGetters("coaches", {
            coaches: "getCoaches"
        })
    },
    methods: {
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapMutations("upload", {
            setImage: "setImage_1",
            clearImage: "clearImages",
            setEditImage: "setEditData"
        }),
        ...mapActions("coaches", {
            fetchCoaches: "fetchCoaches",
            addNew: "addNew",
            editCoach: "editCoach",
            deleteCoach: "deleteCoach",
        }),
        set_edit_data(data) {
            this.coach_id = data.id;
            this.edit_data = data;
            this.setEditImage({
                url_1: String(this.host) + '/' + String(data.photo_url),
            });
        },
    },
    watch: {
        filters: {
            async handler() {
                await this.get_all();
            },
            deep: true
        },
        deep: true

    }
}
</script>