import { create } from 'axios';

const client = create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
});

const attachToken = (config) => {
  const token = localStorage.getItem('token');
  return {
    ...config,
    headers: {
      session_id: `${token}` || undefined,
    },
  };
};

client.interceptors.request.use(attachToken);

export default client;
