import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./scss/custom.scss"
import http from './config/http';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import filters from "@/filters/filters";
import Multiselect from 'vue-multiselect';
import utility from "./mixins/utility";
import { VueEditor } from "vue2-editor";
import Vuelidate from 'vuelidate';
import { BPagination } from 'bootstrap-vue';


Vue.config.productionTip = false;
Vue.component('VueEditor', VueEditor);
Vue.component('DateTimePicker', VueCtkDateTimePicker);
Vue.component('multiselect', Multiselect);
Vue.mixin(utility);
Vue.use(VueToast);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.component('b-pagination', BPagination)
Vue.use(require('vue-moment'));

Vue.prototype.$http = http;

Vue.filter('daysFilter', function (value) {
  return filters.formatDays(value);
});

Vue.filter('cutLongText', function (value) {
  return filters.cutLongText(value);
});

Vue.filter('formatHours', function (value) {
  return filters.formatHours(value);
});
Vue.filter('formatTime', function (value) {
  return filters.formatTime(value);
});

// This interceptor logout user if session not valid
http.interceptors.response.use(
    response => {
        return Promise.resolve(response);
    },
    error => {
        if (['ERR_SESSION_NOT_VALID', 'ERR_BAD_ROLE', 'ERR_BAD_USER_ID'].includes(error.response.data.error)) {
            store.dispatch('auth/logout');
            router.push("/login");
        }
        return Promise.reject(error);
    }
);


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
