<template>
    <b-card class="mt-3 border">
        <b-tabs v-model="tabIndex" content-class="mt-5" active-nav-item-class=" text-dark">
            <b-tab title="Grupe">
                <b-row class="my-3 d-flex">
                    <b-col class="text-left mr-auto">
                        <b-button variant="outline-dark shadow" v-b-modal.create_group_modal>
                            <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                            Kreiraj grupu
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table
                            :items="groups"
                            :fields="fields"
                            hover
                            :busy="loading"
                            responsive
                            class="shadow-lg"
                            head-variant="dark"
                            :striped="true"
                        >
                            <template v-slot:cell(code)="data">
                                <span class="text-monospace">{{ data.item.code }}</span>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <b-button
                                    v-b-modal.display_videos_modal
                                    class="mr-3"
                                    @click="groupName = data.item.name; groupId = data.item.id; fetchVideosForGroup(data.item);"
                                    size="sm"
                                    variant="outline-info">
                                    <i class="fas fa-video" aria-hidden="true"></i>
                                </b-button>
                                <b-button
                                    v-b-modal.edit_group_modal
                                    class="mr-3"
                                    @click="setEditData('group', data.item)"
                                    size="sm"
                                    variant="outline-success">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </b-button>
                                <b-button
                                    v-b-modal.delete_group_modal
                                    @click="groupId = data.item.id"
                                    size="sm"
                                    variant="outline-danger">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </b-button>
                            </template>
                        </b-table>
                        <p v-if="groups.length === 0" class="mt-5">Trenutno nema kreiranih grupa.</p>
                    </b-col>
                </b-row>
            </b-tab>

            <b-tab title="Video materijal">
                <b-row class="my-4">
                    <b-col class="d-flex">
                        <b-col cols="8" class="text-left">
                            <b-button variant="outline-dark shadow" v-b-modal.add_video_modal>
                                <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                                Dodaj video
                            </b-button>
                            <b-button variant="outline-dark shadow" class="ml-2" @click="$bvModal.show('statistic_modal')">
                                <i class="fa fa-download mr-1" aria-hidden="true"></i>
                                Preuzmi statistiku [email]
                            </b-button>
                            <b-button variant="outline-dark shadow" class="ml-2" @click="$bvModal.show('statistic_code_modal')">
                                <i class="fa fa-download mr-1" aria-hidden="true"></i>
                                Preuzmi statistiku [kod]
                            </b-button>
                        </b-col>
                        <b-col class="text-right" v-if="!markVideos">
                            <b-button variant="outline-dark shadow" @click="markVideos = true;">
                                <i class="fa fa-share-alt mr-1" aria-hidden="true"></i>
                                Dodeli video grupi
                            </b-button>
                        </b-col>
                        <b-col class="text-right" v-if="markVideos">
                            <b-button
                                variant="outline-danger"
                                @click="markVideos = false; selectedVideos = [];">
                                <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                                Otkaži
                            </b-button>
                            <b-button
                                variant="outline-success"
                                :disabled="selectedVideos.length < 1"
                                class="ml-2"
                                v-b-modal.assign_video_modal>
                                <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                                Dodeli
                            </b-button>
                        </b-col>
                    </b-col>
                </b-row>
                <b-row cols="12" class="m-0 p-0">
                    <b-col class="m-0 p-0 col-12 col-sm-12 col-md-6 col-lg-4" v-for="video in videos" :key="video.id">
                        <app-video
                            :video="video"
                            type="general"
                            :markVideos="markVideos"
                            @checked="fetchSelectedVideos($event)"
                            @edit="setEditData('video', video); $bvModal.show('edit_video_modal')"
                            @delete="videoId = video.id; $bvModal.show('delete_video_modal')">
                        </app-video>
                    </b-col>
                </b-row>
            </b-tab>

            <b-tab title="Upravljaj kodovima">
                <b-row class="my-4">
                    <b-col class="d-flex mr-auto">
                        <b-button variant="outline-dark shadow" v-b-modal.create_code_modal>
                            <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                            Kreiraj kod
                        </b-button>
                        <div class="ml-auto w-25 d-flex border border-secondary rounded-lg">
                            <b-input-group class="d-flex w-100 shadow-lg">
                                <i class="fa fa-search m-auto px-2" aria-hidden="true"></i>
                                <b-form-input v-model="searchString" class="ml-auto border-0 outline-dark" placeholder="Pretraži kod po nazivu"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="m-0 p-0" cols="12">
                    <b-col class="m-0 p-0 mb-1">
                        <b-table
                            :items="codes"
                            :fields="codeFields"
                            hover
                            responsive
                            @row-clicked="rowClicked"
                            class="shadow-lg"
                            :busy="loading"
                            :tbody-tr-class="rowClass"
                            head-variant="dark"
                            :striped="true"
                        >
                            <template v-slot:cell(code)="data">
                                <span class="text-monospace">{{ data.item.code }}</span>
                            </template>
                            <template v-slot:cell(start_date)="data">
                                <span>{{ data.item.start_date | moment("DD.MM.YYYY") }}</span>
                            </template>
                            <template v-slot:cell(end_date)="data">
                                <span>
                                    {{ data.item.end_date | moment("DD.MM.YYYY") }}
                                </span>
                            </template>
                            <template v-slot:cell(actions)="data">
                                 <button outline="outline-primary" size="sm" @click="rowClicked(data.item)" class="mr-3 pb-1 rounded-sm border border-primary">
                                    <i :class="data.item._showDetails ? 'fa fa-angle-double-up text-primary p-1' : 'fa fa-angle-double-down text-primary p-1'" aria-hidden="true"></i>
                                </button>
                                <b-button
                                    v-b-modal.edit_code_modal
                                    class="mr-3"
                                    @click="setEditData('code', data.item)"
                                    size="sm"
                                    variant="outline-success">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </b-button>
                                <b-button
                                    v-b-modal.delete_code_modal
                                    @click="codeId = data.item.id"
                                    size="sm"
                                    variant="outline-danger">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </b-button>
                            </template>
                            <template v-slot:row-details="row">
                                <b-card class="bg-light">
                                    <b-row v-if="row.item.group_names.length < 1">
                                        <b-col class="text-left">
                                            <span class="text-left">Kod <span class="font-weight-bold">"{{row.item.name}}"</span> trenutno ne pripada ni jednoj grupi.</span>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="row.item.group_names.length > 0">
                                        <b-col class="text-left">
                                            <p class="text-left ml-2">Grupe kojima je dodeljen kod: <span class="font-weight-bold">"{{row.item.name}}"</span></p>
                                        </b-col>
                                    </b-row>
                                    <b-list-group class="text-left">
                                        <b-list-group-item
                                            v-for="(group, i) in row.item.group_names"
                                            :key="group.id">
                                            {{ i + 1 }}. {{ group }}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row v-if="codes && codes.length < 1">
                    <b-col class="my-5">
                        <b-alert show variant="secondary" class="w-50 mx-auto">Nema rezultata za pretragu koju ste uneli.</b-alert>
                    </b-col>
                </b-row>
                <b-row class="align-items-center justify-content-between mt-auto pb-4">
                    <b-col cols="6" class="d-flex justify-content-start align-items-center">
                        <b-pagination
                            v-model="filters.page"
                            :total-rows="codeCount"
                            :per-page="filters.per_page"
                            class="my-0 shadow"
                        ></b-pagination>
                        <h6 class="p-0 m-0 ml-2">Ukupno : {{ codeCount }}</h6>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group
                            label-cols="0"
                            label-cols-sm="5"
                            label-cols-md="6"
                            label-class="text-nowrap"
                            label="Po stranici: "
                            class="mb-0 text-right"
                        >
                            <b-form-select
                                v-model="filters.per_page"
                                :options="pageOptions"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>

        <b-modal
            id="create_group_modal"
            ref="create_group_modal"
            title="Kreiraj novu grupu"
            centered
            @hide="groupForm.name = ''"
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-row>
                            <b-col cols="12">
                                <b-form-input
                                    v-model="groupForm.name"
                                    :class="!$v.groupForm.name.required ? 'border-danger' : ''"
                                    placeholder="Unesite naziv grupe">
                                </b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('create_group_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || !$v.groupForm.name.required"
                    @click="createGroup(groupForm)">
                    <b-spinner v-if="loading" small class="mr-1"></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Kreiraj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_group_modal"
            ref="edit_group_modal"
            title="Izmeni grupu"
            size="lg"
            scrollable
            centered
            @hide="cancelEditGroup();"
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-form-input
                            v-model="editGroupForm.name"
                            :class="!$v.editGroupForm.name.required ? 'border-danger' : ''"
                            placeholder="Unesite naziv grupe">
                        </b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col>
                            <p class="p-0 m-0 my-2">Izaberi kodove: </p>
                            <div class="ml-auto w-100 d-flex border my-2 border-secondary rounded-lg">
                                <b-input-group class="d-flex w-100">
                                    <i class="fa fa-search m-auto px-2" size="sm" aria-hidden="true"></i>
                                    <b-form-input v-model="searchSelectCode" class="ml-auto border-0 outline-dark" placeholder="Pretraži kodove"></b-form-input>
                                </b-input-group>
                            </div>

                            <b-list-group class="custom-list">
                                <b-list-group-item
                                    class="pointer py-2 m-0 pl-2 text-light border border-bottom"
                                    :disabled="loading"
                                    :class="selectedCodeIds.includes(code.id) ? 'bg-success' : 'bg-danger'"
                                    @click="selectCodes(code, i)"
                                    v-for="(code, i) in allCodes"
                                    :key="i">
                                    {{code.name}}
                                </b-list-group-item>
                            </b-list-group>

                        </b-col>
                    </b-row>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button
                    @click="cancelEditGroup()"
                    size="sm"
                    variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || !$v.editGroupForm.name.required"
                    @click="editGroup({form: editGroupForm, selectedCodeIds: selectedCodeIds})">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="create_code_modal"
            ref="create_code_modal"
            title="Kreiraj novi kod"
            @hide="codeForm = {}; codeForm.code = '';"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <span>Naziv koda</span>
                        <b-row>
                            <b-col cols="12">
                                <b-form-input
                                    :class="!$v.codeForm.name.required ? 'border border-danger' : 'border border-dark'"
                                    v-model="codeForm.name" ></b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <span>Kod</span>
                        <b-row>
                            <b-col
                                cols="3"
                                class="ml-3"
                                :class="!$v.codeForm.code.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'">
                                <p class="d-flex justify-content-center mt-2 text-monospace h6 p-0 m-0">
                                    {{ codeForm.code }}
                                </p>
                            </b-col>
                            <b-col cols="8" class="ml-auto">
                                <b-button
                                    variant="outline-dark w-100 h-100"
                                    @click="generateCode(6)">
                                    <i class="fa fa-sync mr-1" aria-hidden="true"></i>
                                    Generiši kod
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <span>Trajanje koda</span>
                        <b-row>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="codeForm.startDate"
                                    only-date
                                    id="1"
                                    color="#787c80"
                                    button-color="#787c80"
                                    overlay
                                    format="YYYY-MM-DD"
                                    label="Datum od"
                                    :class="!$v.codeForm.startDate.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="codeForm.endDate"
                                    only-date
                                    id="2"
                                    color="#787c80"
                                    button-color="#787c80"
                                    :class="!$v.codeForm.endDate.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    overlay
                                    format="YYYY-MM-DD"
                                    label="Datum do"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('create_code_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.codeForm.$invalid"
                    @click="createCode({form: codeForm, searchString: searchString, filters: filters})">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Kreiraj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_code_modal"
            ref="edit_code_modal"
            title="Izmeni kod"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <span>Naziv koda</span>
                        <b-row>
                            <b-col cols="12">
                                <b-form-input
                                    :class="!$v.editCodeForm.name.required ? 'border border-danger' : 'border border-dark'"
                                    v-model="editCodeForm.name" ></b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <span>Kod</span>
                        <b-row>
                            <b-col
                                cols="3"
                                class="ml-3"
                                :class="!$v.editCodeForm.code.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'">
                                <p class="d-flex justify-content-center mt-2 text-monospace h6 p-0 m-0">
                                    {{ editCodeForm.code }}
                                </p>
                            </b-col>
                            <b-col cols="8" class="ml-auto">
                                <b-button
                                    variant="outline-dark w-100 h-100"
                                    @click="generateCode(6)">
                                    <i class="fa fa-sync mr-1" aria-hidden="true"></i>
                                    Generiši kod
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <span>Trajanje koda</span>
                        <b-row>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="editCodeForm.start_date"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    overlay
                                    id="3"
                                    format="YYYY-MM-DD"
                                    label="Datum od"
                                    :class="!$v.editCodeForm.start_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="editCodeForm.end_date"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    :class="!$v.editCodeForm.end_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    overlay
                                    id="4"
                                    format="YYYY-MM-DD"
                                    label="Datum do"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button
                    @click="$bvModal.hide('edit_code_modal')"
                    size="sm"
                    variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.editCodeForm.$invalid"
                    @click="editCode({form: editCodeForm, searchString: searchString, filters: filters})">
                    <b-spinner v-if="$store.state.loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="add_video_modal"
            ref="add_video_modal"
            @hide="videoForm = {}"
            size="lg"
            title="Dodaj video"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group label="Naziv">
                        <b-form-input
                            required
                            v-model="videoForm.name"
                            :class="!$v.videoForm.name.required ? 'border-danger' : ''"
                            placeholder="Unesite naziv"
                        ></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col>
                            <b-form-group label="Naslov [SRB]">
                                <b-form-input
                                    required
                                    v-model="videoForm.title"
                                    :class="!$v.videoForm.title.required ? 'border-danger' : ''"
                                    placeholder="Unesite naslov"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Naslov [ENG]">
                                <b-form-input
                                    required
                                    v-model="videoForm.title_eng"
                                    :class="!$v.videoForm.title_eng.required ? 'border-danger' : ''"
                                    placeholder="Unesite naslov na engleskom"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group label="Opis [SRB]">
                        <b-form-input
                            required
                            v-model="videoForm.description"
                            :class="!$v.videoForm.description.required ? 'border-danger' : ''"
                            placeholder="Unesite opis"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Opis [ENG]">
                        <b-form-input
                            required
                            v-model="videoForm.description_eng"
                            :class="!$v.videoForm.description_eng.required ? 'border-danger' : ''"
                            placeholder="Unesite opis na engleskom"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="URL">
                        <b-form-input
                            required
                            v-model="videoForm.url"
                            :class="!$v.videoForm.url.required || !$v.videoForm.url.url ? 'border-danger' : ''"
                            placeholder="Unesite URL"
                        ></b-form-input>
                        <small v-if="!$v.videoForm.url.url" class="text-danger">
                            * URL nije u ispravnom formatu
                        </small>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('add_video_modal');" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    :disabled="loading || $v.videoForm.$invalid"
                    @click="createVideo(videoForm)"
                    variant="outline-success">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodaj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_video_modal"
            ref="edit_video_modal"
            size="lg"
            title="Izmeni video"
            centered
            @hide="videoForm = {};"
        >
            <b-container>
                <b-form>
                    <b-form-group label="Naziv">
                        <b-form-input
                            required
                            v-model="videoForm.name"
                            :class="!$v.videoForm.name.required ? 'border-danger' : ''"
                            placeholder="Unesite naziv"
                        ></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col>
                            <b-form-group label="Naslov [SRB]">
                                <b-form-input
                                    required
                                    v-model="videoForm.title"
                                    :class="!$v.videoForm.title.required ? 'border-danger' : ''"
                                    placeholder="Unesite naslov"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Naslov [ENG]">
                                <b-form-input
                                    required
                                    v-model="videoForm.title_eng"
                                    :class="!$v.videoForm.title_eng.required ? 'border-danger' : ''"
                                    placeholder="Unesite naslov na engleskom"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group label="Opis [SRB]">
                        <b-form-input
                            required
                            v-model="videoForm.description"
                            :class="!$v.videoForm.description.required ? 'border-danger' : ''"
                            placeholder="Unesite opis"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Opis [ENG]">
                        <b-form-input
                            required
                            v-model="videoForm.description_eng"
                            :class="!$v.videoForm.description_eng.required ? 'border-danger' : ''"
                            placeholder="Unesite opis na engleskom"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="URL">
                        <b-form-input
                            required
                            v-model="videoForm.url"
                            :class="!$v.videoForm.url.required ? 'border-danger' : ''"
                            placeholder="Unesite URL"
                        ></b-form-input>
                        <small v-if="!$v.videoForm.url.url" class="text-danger">
                            * url nije u ispravnom formatu
                        </small>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_video_modal'); fetchVideos();" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    @click="editVideo(videoForm)"
                    :disabled="loading || $v.videoForm.$invalid">
                    <b-spinner v-if="loading" small class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="assign_video_modal"
            ref="assign_video_modal"
            title="Dodeli izabrani video materijal grupi"
            centered
            @hide="selectedVideos = []; markVideos = false;"
        >
            <b-container>
                <multiselect
                    v-model="selectedGroup"
                    :options="groups"
                    select-label="Izaberi"
                    selected-label="Izabrano"
                    deselect-label="Izbaci"
                    :limit="3"
                    track-by="id"
                    label="name"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="Izaberi grupu">
                </multiselect>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('assign_video_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    @click="assignVideosToGroup({videos: selectedVideos, selectedGroup: selectedGroup})"
                    :disabled="loading || !selectedGroup">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodeli
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="display_videos_modal"
            ref="display_videos_modal"
            :title="'Prikaz video materijala za grupu: ' + groupName"
            size="xl"
            header-bg-variant="primary"
            scrollable
            centered
        >
            <b-container>
                <b-row v-if="groupVideos && groupVideos.length > 0" class="m-0 p-0 h-100 w-100" cols="12">
                    <b-col class="m-0 p-0 col-12 col-sm-12 col-md-6 col-lg-3" v-for="video in groupVideos" :key="video.id">
                        <app-video
                            :video="video"
                            type="groups"
                            class="h-75 w-75 mx-auto"
                            @remove="$bvModal.show('remove_video_from_group'); videoId = $event;">
                        </app-video>
                    </b-col>
                </b-row>
                <b-row v-if="groupVideos && groupVideos.length < 1">
                    <b-col class="py-5 my-5">
                        <h5 class="text-center">Trenutno nema ni jedan dodeljen video.</h5>
                    </b-col>
                </b-row>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('display_videos_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    @click="$bvModal.hide('display_videos_modal')"
                    :disabled="loading"
                    variant="outline-success">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Gotovo
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="statistic_modal"
            ref="statistic_modal"
            title="Preuzmi statistiku"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-form-input
                            required
                            v-model="statisticForm.email"
                            placeholder="Unesite email (Opciono)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-row>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="statisticForm.start_date"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    overlay
                                    id="5"
                                    format="YYYY-MM-DD"
                                    label="Datum od"
                                    :class="!$v.statisticForm.start_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="statisticForm.end_date"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    :class="!$v.statisticForm.end_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    overlay
                                    id="6"
                                    format="YYYY-MM-DD"
                                    label="Datum do"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('statistic_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    @click="downloadXls"
                    :disabled="loading || $v.statisticForm.$invalid">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i class="fa fa-download mr-1" aria-hidden="true"></i>
                    Preuzmi
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="statistic_code_modal"
            ref="statistic_code_modal"
            title="Preuzmi statistiku"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-form-input
                            required
                            v-model="statisticCodeForm.code"
                            placeholder="Unesite kod (Opciono)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-row>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="statisticCodeForm.start_date"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    overlay
                                    format="YYYY-MM-DD"
                                    id="7"
                                    label="Datum od"
                                    :class="!$v.statisticCodeForm.start_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                            <b-col cols="6">
                                <date-time-picker
                                    v-model="statisticCodeForm.end_date"
                                    only-date
                                    color="#787c80"
                                    button-color="#787c80"
                                    :class="!$v.statisticCodeForm.end_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
                                    overlay
                                    format="YYYY-MM-DD"
                                    label="Datum do"
                                    id="8"
                                    button-now-translation="Današnji datum"
                                    left
                                    light
                                    no-header
                                    no-shortcuts
                                    auto-close
                                ></date-time-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('statistic_code_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    @click="downloadXlsByCode"
                    :disabled="loading || $v.statisticCodeForm.$invalid">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i class="fa fa-download mr-1" aria-hidden="true"></i>
                    Preuzmi
                </b-button>
            </template>
        </b-modal>

        <confirm-delete-modal
            :modal-name="'delete_video_modal'"
            :description="'video'"
            @confirm="deleteVideo(videoId)"/>
        <confirm-delete-modal
            :modal-name="'delete_group_modal'"
            :description="'grupu'"
            @confirm="deleteGroup(groupId)"/>
        <confirm-delete-modal
            :modal-name="'delete_code_modal'"
            :description="'kod'"
            @confirm="deleteCode({codeId: codeId, searchString: searchString, filters: filters})"/>
        <confirm-delete-modal
            :modal-name="'remove_video_from_group'"
            :description="'video iz grupe'"
            @confirm="removeVideoFromGroup({videoId: videoId, groupId: groupId})"/>
    </b-card>
</template>

<script>
import AppVideo from "../components/AppVideo";
import {email, required, url} from 'vuelidate/lib/validators';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {PaginationFilters} from "../mixins/pagination";
import _ from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "VideoArchive",
    mixins: [PaginationFilters],
    data() {
        return {
            videoId: null,
            groupId: null,
            codeId: null,
            groupName: null,
            selectedGroup: null,
            markVideos: false,
            searchString: '',
            tabIndex: 0,
            searchSelectCode: '',
            searchSelectedCode: '',
            date: null,
            statisticForm: {
                email: '',
                start_date: '',
                end_date: '',
            },
            statisticCodeForm: {
                code: '',
                start_date: '',
                end_date: '',
            },
            groupForm: {
                code: '',
            },
            editGroupForm: {
                selectedCodes: [],
            },
            videoForm: {},
            codeForm: {
                code: '',
                startDate: '',
                endDate: '',
            },
            editCodeForm: {
                name: '',
                code: '',
                startDate: '',
                endDate: '',
            },
            selectedCodes: [],
            allCodes: [],
            copyOfAllCodes: [],
            copyOfAllSelectedCodes: [],
            selectedCodeIds: [],
            selectedVideos: [],
            fields: [
                { key: "name", label: "Naziv grupe", class: "text-left" },
                { key: "actions", label: "Akcije", class: "text-right", thClass: "pr-5" },
            ],
            codeFields: [
                { key: "name", label: "Naziv", class: "text-left" },
                { key: "code", label: "Kod" },
                { key: "start_date", label: "Važi od" },
                { key: "end_date", label: "Važi do" },
                { key: "actions", label: "Akcije", class: "text-right" },
            ],
        }
    },
    validationGroup: ['groupForm', 'videoForm', 'codeForm', 'editCodeForm', 'statisticForm', 'statisticCodeForm'],
    validations: {
        groupForm: {
            name: {
                required,
            },
        },
        editGroupForm: {
            name: {
                required,
            },
        },
        videoForm: {
            name: {
                required,
            },
            title: {
                required,
            },
            title_eng: {
                required,
            },
            description: {
                required,
            },
            description_eng: {
                required,
            },
            url: {
                required,
                url,
            },
        },
        codeForm: {
            name: {
                required,
            },
            code: {
                required,
            },
            startDate: {
                required,
            },
            endDate: {
                required,
            },
        },
        editCodeForm: {
            name: {
                required,
            },
            code: {
                required,
            },
            start_date: {
                required,
            },
            end_date: {
                required,
            },
        },
        statisticForm: {
            email: {
                email,
            },
            start_date: {
                required,
            },
            end_date: {
                required,
            },
        },
        statisticCodeForm: {
            start_date: {
                required,
            },
            end_date: {
                required,
            },
        },

    },
    components: {AppVideo, ConfirmDeleteModal},
    mounted() {
        this.getAllCodes();
        this.fetchGroups();
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("codes", {
            codeCount: "getCodeCount",
            codes: "getCodes",
        }),
        ...mapGetters("groups", {
            groups: "getGroups",
            groupVideos: "getGroupVideos",
        }),
        ...mapGetters("videos", {
            videos: "getVideos",
        }),
    },
    methods: {
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapActions("codes", {
            fetchCodes: "fetchCodes",
            createCode: "createCode",
            editCode: "editCode",
            deleteCode: "deleteCode",
        }),
        ...mapActions("groups", {
            fetchGroups: "fetchGroups",
            createGroup: "createGroup",
            editGroup: "editGroup",
            deleteGroup: "deleteGroup",
            fetchVideosForGroup: "fetchVideosForGroup",
            assignVideosToGroup: "assignVideosToGroup",
            removeVideoFromGroup: "removeVideoFromGroup",
        }),
         ...mapActions("videos", {
            fetchVideos: "fetchVideos",
            createVideo: "createVideo",
            editVideo: "editVideo",
            deleteVideo: "deleteVideo",
        }),
        generateCode(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            this.codeForm.code = result.toLowerCase();
            this.editCodeForm.code = result.toLowerCase();
        },
        // Edit group code list - start
        searchCode(type, string, list) {
            var codes = list;
            string = string.toLowerCase();
            if (type === 'selectList') {
                this.allCodes = [];
                codes.forEach((item, i) => {
                    if (item.name.toLowerCase().indexOf(string) !== -1) {
                        this.allCodes.push(item);
                    }
                });
                this.updateCodeList();
            } // else if (type === 'selectedList') {
            //     this.selectedCodes = [];
            //     codes.forEach((item, i) => {
            //         if (item.name.toLowerCase().indexOf(string) !== -1) {
            //             this.selectedCodes.push(item);
            //         }
            //     });
            //     this.updateCodeList();
            // }
        },
        async getAllCodes() {
            const { data } = await this.$http.get('/bo/code');
            this.allCodes = data;
            this.copyOfAllCodes = data;
        },
        async selectCodes(code, i) {
            if (!this.selectedCodeIds.includes(code.id)) {
                this.selectedCodeIds.push(code.id);
            } else {
                const index = this.selectedCodeIds.indexOf(code.id);
                if (index > -1) {
                    this.selectedCodeIds.splice(index, 1);
                }
            }
        },
        async updateCodeList() {
            Object.keys(this.allCodes).forEach((key) => {
                this.selectedCodeIds.forEach((item) => {
                    if (this.allCodes[key].id === item) {
                        this.allCodes[key].selected = true;
                    } else {
                        this.allCodes[key].selected = false;
                    }
                })
            })
        },
        // Edit group code list - end
        cancelEditGroup() {
            this.searchSelectCode = '';
            this.searchSelectedCode = '';
            this.selectedCodeIds = [];
            this.selectedCodes = [];
            this.copyOfAllSelectedCodes = [];
            this.allCodes = [];
            this.$bvModal.hide('edit_group_modal');
            this.getAllCodes();
            this.fetchGroups();
        },
        async setEditData(type, data) {
            if (type === 'video') {
                this.videoForm = data;
            }
            if (type === 'group') {
                this.getAllCodes();
                this.editGroupForm = data;
                this.groupId = data.id;
                this.groupName = data.name;
                this.selectedCodes = data.codes;
                this.copyOfAllSelectedCodes = data.codes;
                data.codes.forEach((item, i) => {
                    this.selectedCodeIds.push(item.id);
                })
                this.updateCodeList();
            }
            if (type === 'code') {
                this.editCodeForm = data;
            }
        },
        fetchSelectedVideos(video_id) {
            if (this.selectedVideos.indexOf(video_id) === -1) {
                this.selectedVideos.push(video_id)
            } else {
                delete this.selectedVideos[this.selectedVideos.indexOf(video_id)];
            }
        },
        rowClicked(item, index) {
            this.$set(item, '_showDetails', !item._showDetails)
        },
        downloadXlsByCode() {
            const timestamp = Math.floor(Date.now() / 1000);
            let url = '';
            if (this.statisticCodeForm.code !== '') {
                url = `/bo/group/video/user?start_date=${this.statisticCodeForm.start_date}&end_date=${this.statisticCodeForm.end_date}&code=${this.statisticCodeForm.code}&timestamp=${timestamp}`
            } else {
                url = `/bo/group/video/user?start_date=${this.statisticCodeForm.start_date}&end_date=${this.statisticCodeForm.end_date}&timestamp=${timestamp}`
            }
            this.setLoading(true);
            this.$http({
                url: url,
                method: 'GET',
                'Content-Type': 'application/vnd.ms-excel',
                responseType: 'blob',
            }).then((response) => {
                this.$bvModal.hide('statistic_modal');
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', `Izvestaj od ${this.statisticForm.start_date} - ${this.statisticForm.end_date}` + '.csv');
                document.body.appendChild(fileLink);
                this.setLoading(false);
                fileLink.click();
            }).catch((err) => {
                this.setLoading(false);
                this.$bvModal.hide('statistic_modal');

            })
        },
        async downloadXls() {
            const timestamp = Math.floor(Date.now() / 1000);
            let url = '';
            if (this.statisticForm.email !== '') {
                url = `/bo/group/video/device?start_date=${this.statisticForm.start_date}&end_date=${this.statisticForm.end_date}&email=${this.statisticForm.email}&timestamp=${timestamp}`
            } else {
                url = `/bo/group/video/device?start_date=${this.statisticForm.start_date}&end_date=${this.statisticForm.end_date}&timestamp=${timestamp}`
            }
            this.setLoading(true);
            this.$http({
                url: url,
                method: 'GET',
                'Content-Type': 'application/vnd.ms-excel',
                responseType: 'blob',
            }).then((response) => {
                this.$bvModal.hide('statistic_modal');
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', `Izvestaj od ${this.statisticForm.start_date} - ${this.statisticForm.end_date}` + '.csv');
                document.body.appendChild(fileLink);
                this.setLoading(false);
                fileLink.click();
            }).catch((err) => {
                this.setLoading(false);s
                this.$bvModal.hide('statistic_modal');

            })
        },
        rowClass(item, type) {
            if (item && type === 'row') {
                var start_date = item.start_date;
                var end_date = item.end_date;
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;
                if (today <= end_date && today >= start_date ) {
                    return 'table-success';
                } else {
                    return 'table-danger';
                }
            }
        },
    },
    watch: {
        selectedCodeIds(val) {
            this.updateCodeList();
        },
        tabIndex(val) {
            if (val === 0) {
                // this.getAllCodes();
                this.fetchGroups();
            } else if (val === 1) {
                this.fetchVideos();
            } else {
                this.getAllCodes();
                this.fetchCodes({searchString: this.searchString, filters: this.filters});
            }
        },
        searchSelectCode: _.debounce(function(val) {
            this.searchCode('selectList', val, this.copyOfAllCodes);
        }, 400),
        searchSelectedCode: _.debounce(function(val) {
            this.searchCode('selectedList', val, this.copyOfAllSelectedCodes);
        }, 400),
        searchString: _.debounce(function() {
            this.fetchCodes({searchString: this.searchString, filters: this.filters});
        }, 400),
        filters: {
            async handler() {
                await this.fetchCodes({searchString: this.searchString, filters: this.filters});
            },
            deep: true
        },
        deep: true
    }
}
</script>