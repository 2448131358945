export const roles = [
    { key: 'admin', name: 'Administrator' },
    { key: 'accounting', name: 'Računovođa' },
    { key: 'marketing', name: 'Marketing' },
    { key: 'user', name: 'Korisnik' },
    { key: 'guest', name: 'Gost' },
    { key: 'front_desk_banjica', name: 'Pult Banjica' },
    { key: 'front_desk_zemun', name: 'Pult Zemun' },
    { key: 'front_desk_vracar', name: 'Pult Vračar' },
    { key: 'front_desk_ustanicka', name: 'Pult Ustanička' },
    { key: 'front_desk_navigator', name: 'Pult Navigator' },
    { key: 'front_desk_pionir', name: 'Pult Pionir' },
    { key: 'front_desk_usce', name: 'Pult Ušće' },
    { key: 'front_desk_blokovi', name: 'Pult Blokovi' },
]