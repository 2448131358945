import http from "../config/http"
export default {
    namespaced: true,
    state: {
        promotions: [],
    },
    mutations: {
        setPromotions(state, data) {
          state.promotions = data;
        }
    },
    actions: {
        async fetchPromotions(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/promotion');
            if (data) {
                data.sort((a, b) => (a.end_date < b.end_date ) ? 1 : -1);
                context.commit("setPromotions", data);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
        async createPromotion(context, data) {
            const apiData = {
                title: data.title,
                start_date: data.start_date,
                end_date: data.end_date,
            };
            context.commit('loader/setLoading', true, { root: true });
            await http.post(`/bo/promotion`, apiData)
                .then((resp) => {
                    context.dispatch("fetchPromotions");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE KREIRALI PROMOCIJU', { root: true });
                    context.commit('shared/setModal', {name: 'new_promotion_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_promotion_modal', show: false}, { root: true });
                })
        },
        async editPromotion(context, data) {
            const apiData = {
                title: data.title,
                start_date: data.start_date,
                end_date: data.end_date,
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/promotion/${data.id}`, apiData)
                .then((resp) => {
                    context.dispatch("fetchPromotions");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI PROMOCIJU', { root: true });
                    context.commit('shared/setModal', {name: 'edit_promotion_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_promotion_modal', show: false}, { root: true });
                })
        },
        async deletePromotion(context, promotionId) {
            await http.delete(`/bo/promotion/${promotionId}`)
                .then((resp) => {
                    context.dispatch("fetchPromotions");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI PROMOCIJU', { root: true });
                    context.commit('shared/setModal', {name: 'delete_promotion_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'delete_promotion_modal', show: false}, { root: true });
                })
        },
    },
    getters: {
        getPromotions(state) {
          return state.promotions;
        },
    }
}