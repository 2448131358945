<template>
    <div class="border mt-4 px-2 mx-0 ml-64">
        <b-row class="mb-4 mt-4">
            <b-col class="text-left">
                <b-button variant="outline-dark shadow" v-b-modal.new_blog_modal @click="modal_button_disabled = false">
                    <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                    Dodaj blog
                </b-button>
            </b-col>
        </b-row>
        <b-row>
             <b-col>
                <b-table
                   :items="blogs"
                   :fields="fields"
                   class="shadow"
                   hover
                   :busy="loading"
                   responsive
                   head-variant="dark"
                   :striped="true"
                >
                    <template v-slot:cell(date_of_creation)="data">
                        {{ data.item.date_of_creation | moment('DD.MM.YYYY') }}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            v-b-modal.edit_blog_modal
                            @click="set_edit_data(data.item)"
                            size="sm"
                            class="mr-3"
                            variant="outline-success shadow">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.confirm_delete_modal
                            @click="blog_id = data.item.id"
                            size="sm"
                            variant="outline-danger shadow">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
                 <p v-if="blogs.length === 0" class="mt-5">Trenutno nema bloga</p>
             </b-col>
        </b-row>
        <b-modal
            id="new_blog_modal"
            ref="new_blog_modal"
            size="lg"
            @hide="form = {}; clearImage(null);"
            centered
            scrollable
        >
            <b-container>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <b-form-input
                                    required
                                    v-model="form.header"
                                    maxlength=51
                                    placeholder="Unesite naslov bloga (obavezno)"
                                ></b-form-input>
                                <small :class="!$v.form.header.maxLength ? 'd-block' : 'd-none' " class="text-danger">
                                    Prekoračili ste dozvoljen broj karaktera.</small>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group>
                                <b-form-input
                                    required
                                    v-model="form.header_eng"
                                    maxlength=51
                                    placeholder="Unesite naslov bloga na engleskom (obavezno)"
                                ></b-form-input>
                                <small> <p :class="!$v.form.header_eng.maxLength ? 'd-block' : 'd-none' " class="text-danger">
                                    Presli ste maksimalan broj karaktera</p></small>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <vue-editor
                                    required
                                    class="text-editor"
                                    v-model="form.text"
                                    placeholder="Unesite tekst bloga na srpskom (obavezno)"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group>
                                <vue-editor
                                    required
                                    v-model="form.text_eng "
                                    class="text-editor"
                                    placeholder="Unesite tekst bloga na engleskom (obavezno)"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group class="mt-2">
                        <div v-if="image.url" class="text-center mb-3 bg-primary p-3 rounded">
                             <b-img :src="image.url" v-bind="image_size" class="img-thumbnail"/>
                        </div>
                        <b-form-file
                            required
                            accept="image/*"
                            type="file"
                            @change="setImage"
                            placeholder="Izaberite sliku (obavezno)">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  500 x 500</small>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('new_blog_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.form.$invalid || !image.image"
                    @click="addNew(form)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodaj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_blog_modal"
            ref="edit_blog_modal"
            scrollable
            size="lg"
            @hide="edit_data = {}; clearImage(null);"
            title="Izmeni blog"
            centered
        >
            <b-container>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <b-form-input
                                    required
                                    v-model="edit_data.header"
                                    maxlength=51
                                    placeholder="Unesite naslov bloga"
                                ></b-form-input>
                                <small :class="!$v.edit_data.header.maxLength ? 'd-block' : 'd-none' " class="text-danger">
                                    Presli ste maksimalan broj karaktera</small>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group>
                                <b-form-input
                                    required
                                    v-model="edit_data.header_eng"
                                    maxlength=51
                                    placeholder="Unesite naslov bloga na engleskom"
                                ></b-form-input>
                                <small> <p :class="!$v.edit_data.header_eng.maxLength ? 'd-block' : 'd-none' " class="text-danger">
                                    Presli ste maksimalan broj karaktera</p></small>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <vue-editor
                                    v-model="edit_data.text"
                                    class="text-editor"
                                    placeholder="Unesite tekst bloga na srpskom"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group>
                                <vue-editor
                                    v-model="edit_data.text_eng"
                                    class="text-editor"
                                    placeholder="Unesite tekst bloga na engleskom"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group class="mt-2">
                         <b-form-group>
                             <div class="text-center mb-3 bg-primary p-2 rounded">
                                 <b-img v-if="image.url" :src="image.url" v-bind="image_size" class="img-thumbnail"/>
                             </div>
                             <b-form-file
                                 accept="image/*"
                                 type="file"
                                 @change="setImage"
                                 placeholder="Izaberite sliku">
                             </b-form-file>
                             <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  500 x 500</small>
                         </b-form-group>
                     </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_blog_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.edit_data.$invalid"
                    @click="editBlog(edit_data)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <confirm-delete-modal
            :modal-name="'confirm_delete_modal'"
            :description="'blog'"
            @confirm="deleteBlog(blog_id)"/>
    </div>
</template>

<script>
import { required , maxLength} from 'vuelidate/lib/validators';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
    name: "Blog",
    data() {
        return {
            host: process.env.VUE_APP_API_URL,
            image_size: { width: 200, height: 200 },
            form: {},
            customToolbar: [["bold", "italic", "underline", "link"]],
            edit_data: {},
            blog_id: null,
            fields: [
                { key: "header", label: "Naslov (Srb)", class: "text-left" },
                { key: "date_of_creation", label: "Datum kreiranja", class: "d-none d-sm-none d-md-table-cell d-lg-table-cell" },
                { key: "actions", label: "Akcije", class: "text-right" },
            ],
        }
    },
    components: {ConfirmDeleteModal},
    validationGroup: ['form', 'edit_data'],
    validations: {
        form: {
            header: {
                required,
                maxLength: maxLength(50),
            },
            header_eng: {
                required,
                maxLength: maxLength(50),
            },
            text: {
                required,
            },
            text_eng: {
                required,
            },
        },
        edit_data: {
            header: {
                required,
                maxLength: maxLength(50),
            },
            header_eng: {
                required,
                maxLength: maxLength(50),
            },
            text: {
                required,
            },
            text_eng: {
                required,
            },
        },
    },
    mounted() {
        this.fetchBlogs();
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("blog", {
            blogs: "getBlogs"
        }),
        ...mapGetters("upload", {
            image: "getImage_1"
        })
    },
    methods: {
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapMutations("upload", {
            setImage: "setImage_1",
            clearImage: "clearImages",
            setEditImage: "setEditData"
        }),
        ...mapActions("blog", {
            fetchBlogs: "fetchBlogs",
            addNew: "addNew",
            editBlog: "editBlog",
            deleteBlog: "deleteBlog",
        }),
        set_edit_data(data) {
            this.blog_id = data.id;
            this.edit_data = data;
            this.setEditImage({
                url_1: String(this.host) + '/' + String(data.photo_url),
            });
        },
    }
}
</script>