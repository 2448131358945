<template>
    <b-navbar fixed="top" variant="dark" type="dark" class="shadow bg-dark p-0">
        <div @click="$router.push('/')" class="btn-collapse">
            <logo></logo>
        </div>
        <b-navbar-nav class="ml-auto bg-dark">
            <b-nav-item>
                <b-button @click="logout">
                    <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
                    Odjavi se
                </b-button>
            </b-nav-item>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
import Logo from "../../icons/Logo";
import { mapActions } from "vuex";
export default {
    name: "TopBar",
    components: {Logo},
    methods: {
        ...mapActions("auth", {
            logout: "logout"
        }),
        // logout() {
        //     this.$store.commit('logout_user');
        //     this.$router.push("/login");
        // },
    }
}
</script>