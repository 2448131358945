<template>
    <b-card class="mt-4">
        <b-row class="mb-4">
            <b-col class="text-left">
                <b-button variant="outline-dark shadow" v-b-modal.new_location_modal>
                    <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                    Dodaj lokaciju
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    :items="gyms"
                    :fields="fields"
                    hover
                    :busy="loading"
                    responsive
                    class="shadow"
                    head-variant="dark"
                    :striped="true"
                >
                    <template v-slot:cell(actions)="data">
                        <b-button
                            v-b-modal.room_config_modal
                            @click="fetchRoomsForGym(data.item.id); club_id = data.item.id;"
                            size="sm"
                            class="mr-3" variant="outline-warning shadow">
                            <i class="fas fa-school" size="sm" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.edit_location_modal
                            @click="set_edit_data(data.item)"
                            class="mr-3"
                            size="sm"
                            variant="outline-success shadow">
                            <i class="fa fa-edit" size="sm" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.confirm_delete_modal
                            @click="club_id = data.item.id"
                            size="sm"
                            variant="outline-danger shadow">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
                <p v-if="gyms.length === 0" class="mt-5">Trenutno nema lokacija</p>
            </b-col>
        </b-row>
        <b-modal
            id="new_location_modal"
            ref="new_location_modal"
            @hide="form = {}"
            title="Napravi novu lokaciju"
            centered
        >
            <b-container>
                <b-form>
                    <b-form-group id="input-group-1" label-for="input-1">
                        <b-form-input
                            id="input-1"
                            required
                            :class="!$v.form.name.required ? 'border-danger' : ''"
                            v-model="form.name"
                            placeholder="Unesite ime lokacije (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            :class="!$v.form.address.required ? 'border-danger' : ''"
                            v-model="form.address"
                            placeholder="Unesite adresu (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="input-2">
                        <b-form-input
                            id="input-2"
                            required
                            :class="!$v.form.phone.required ? 'border-danger' : ''"
                            v-model="form.phone"
                            placeholder="Unesite telefon (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="input-2">
                        <b-form-input
                            id="input-2"
                            required
                            v-model="form.phone_2"
                            placeholder="Unesite drugi telefon (opciono)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            :class="!$v.form.weekdays.required ? 'border-danger' : ''"
                            v-model="form.weekdays"
                            placeholder="Unesite radno vreme za radne dane (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            :class="!$v.form.saturday.required ? 'border-danger' : ''"
                            v-model="form.saturday"
                            placeholder="Unesite radno vreme za subotu (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            :class="!$v.form.sunday.required ? 'border-danger' : ''"
                            required
                            v-model="form.sunday"
                            placeholder="Unesite radno vreme za nedelju (obavezno)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox v-model="form.gym">Teretana</b-form-checkbox>
                        <b-form-checkbox v-model="form.fitness">Fitness</b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('new_location_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button size="sm" variant="outline-success" :disabled="$v.form.$invalid || loading" @click="addNew(form)">
                    <b-spinner class="mr-1" v-if="loading" small></b-spinner>
                    <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodaj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_location_modal"
            ref="edit_location_modal"
            title="Izmeni lokaciju"
            centered
        >
            <b-container>
                <b-form @submit="">
                    <b-form-group label-for="input-1">
                        <b-form-input
                            id="input-1"
                            required
                            v-model="edit_data.name"
                            placeholder="Unesite ime lokacije"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            v-model="edit_data.address"
                            placeholder="Unesite adresu"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            v-model="edit_data.phone"
                            placeholder="Unesite telefon"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="input-2">
                        <b-form-input
                            id="input-2"
                            required
                            v-model="edit_data.phone_2"
                            placeholder="Unesite drugi telefon (opciono)"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            v-model="edit_data.weekdays"
                            placeholder="Unesite radno vreme za radne dane"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            v-model="edit_data.saturday"
                            placeholder="Unesite radno vreme za subotu"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            id="input-2"
                            required
                            v-model="edit_data.sunday"
                            placeholder="Unesite radno vreme za nedelju"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox v-model="edit_data.gym">Teretana</b-form-checkbox>
                        <b-form-checkbox v-model="edit_data.fitness">Fitness</b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_location_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button size="sm" variant="outline-success" :disabled="$store.state.loading" @click="editLocation(edit_data, club_id)">
                    <b-spinner class="mr-1" v-if="loading" small></b-spinner>
                    <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <!--        sobe za teretanu ovdee-->

        <b-modal
            id="room_config_modal"
            ref="room_config_modal"
            @hide="roomTitle = ''; addRoom = false;"
            size="lg"
            hide-footer
            hide-header
            centered
        >
            <b-container class="p-2">
                <b-row>
                    <b-col style="min-height: 220px; max-height: 420px; overflow: auto">
                        <b-table
                            :items="rooms"
                            :fields="roomFields"
                            hover
                            small
                            head-variant="dark"
                            :striped="true"
                        >
                            <template v-slot:cell(actions)="data">
                                <b-button
                                    v-b-modal.edit_room_modal
                                    class="mr-3"
                                    @click="roomTitle = data.item.name; room_id = data.item.id"
                                    size="sm"
                                    variant="outline-success">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </b-button>
                                <b-button
                                    v-b-modal.confirm_delete_room_modal
                                    @click="room_id = data.item.id"
                                    size="sm"
                                    variant="outline-danger">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </b-button>
                            </template>
                        </b-table>
                        <p v-if="rooms.length === 0" class="text-center mt-5 pt-5">Trenutno nema registrovanih sala!</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="!loading" class="w-100 mt-4 text-left">
                        <div>
                            <b-button variant="outline-dark" @click="addRoom = !addRoom">
                                <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                                Dodaj salu
                            </b-button>
                        </div>
                        <div class="mt-3" v-if="addRoom">
                            <div>
                                <b-row>
                                    <b-col cols="12" class="my-2 d-flex">
                                        <b-col cols="6" class="pl-0 ml-0">
                                            <b-form-input
                                                :class="!$v.roomTitle.required ? 'border-danger' : ''"
                                                v-model="roomTitle"
                                                placeholder="Unesite naziv sale">
                                            </b-form-input>
                                        </b-col>
                                        <b-col cols="6" class="pl-0 ml-0">
                                            <!--                                           <div class="ml-auto d-flex col-5">-->
                                            <b-button
                                                class="ml-3"
                                                @click="closeAddRoom()"
                                                variant="outline-danger">
                                                <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                                                Otkaži
                                            </b-button>
                                            <b-button
                                                class="ml-3"
                                                @click="addNewRoom({clubId: club_id, roomTitle: roomTitle})"
                                                :disabled="loading || $v.roomTitle.$invalid"
                                                variant="outline-success">
                                                <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                                                <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                                                Dodaj
                                            </b-button>
                                            <!--                                           </div>-->
                                        </b-col>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>

        <b-modal
            id="edit_room_modal"
            ref="edit_room_modal"
            @hide="roomTitle = ''; addRoom = false;"
            hide-footer
            hide-header
            centered
        >
            <b-container class="w-100 d-flex-inline">
                <b-row>
                    <b-col>
                        <h6 class="my-2 mb-3">Izmenite naziv sale</h6>
                        <b-form-input
                            :class="!$v.roomTitle.required ? 'border-danger' : ''"
                            v-model="roomTitle"
                            placeholder="Unesite naziv sale"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="d-flex justify-content-end mt-4">
                        <b-button class="mr-2" size="sm" variant="outline-danger" @click="$refs['edit_room_modal'].hide();">
                            <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                            Otkaži
                        </b-button>
                        <b-button
                            variant="outline-success"
                            size="sm"
                            :disabled="loading || $v.roomTitle.$invalid"
                            @click="editRoom({gymId: club_id, roomId: room_id, roomTitle: roomTitle})">
                            <i class="fa fa-check-square mr-1" aria-hidden="true"></i>
                            Potvrdi
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>

        <confirm-delete-modal
            :modal-name="'confirm_delete_room_modal'"
            :description="'salu'"
            @confirm="deleteRoom({gymId: club_id, roomId: room_id})"/>

        <confirm-delete-modal
            :modal-name="'confirm_delete_modal'"
            :description="'lokaciju'"
            @confirm="deleteLocation(club_id)"/>
    </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
export default {
    name: "Clubs",
    data() {
        return {
            form: {},
            edit_data: {},
            club_id: null,
            room_id: null,
            addRoom: false,
            roomTitle: '',
            fields: [
                { key: "name", label: "Naziv", class: "text-left" },
                { key: "actions", label: "Akcije", class: "text-right" },
            ],
            roomFields: [
                { key: "name", label: "Naziv", class: 'text-left' },
                { key: "actions", label: "Akcije", class: 'text-right' },
            ],
            name: '',
        }
    },
    components: {ConfirmDeleteModal},
    validationGroup: ['form', 'roomTitle'],
    validations: {
        form: {
            name: {
                required,
            },
            address: {
                required,
            },
            phone: {
                required,
            },
            weekdays: {
                required,
            },
            saturday: {
                required,
            },
            sunday: {
                required,
            },
        },
        roomTitle: {
            required
        }
    },
    mounted() {
        if (!this.gyms || this.gyms.length < 1) {
            this.fetchGyms();
        }
    },
    computed: {
        ...mapGetters("gyms", {
            gyms: "getGyms",
            rooms: "getGymRooms",
        }),
        ...mapGetters("loader", {
            loading: "getLoading",
        })
    },
    methods: {
        ...mapActions("gyms", {
            fetchGyms: "fetchGyms",
            addNew: "addNew",
            editLocation: "editLocation",
            deleteLocation: "deleteLocation",
            fetchRoomsForGym: "fetchRoomsForGym",
            addNewRoom: "addNewRoom",
            editRoom: "editRoom",
            deleteRoom: "deleteRoom",
        }),
        set_edit_data(data) {
            this.club_id = data.id;
            this.edit_data = data;
        },
        closeAddRoom() {
            this.addRoom = false;
            this.roomTitle = '';
        },
    },
}
</script>