import Vue from 'vue'
import {mapGetters} from "vuex/dist/vuex.mjs";
export default {
    data() {
        return {
            error: '',
            messageType: '',
            errorMessages: {
                ERR_DUPLICATED_EMAIL: 'ERR_MEJL_VEĆ_POSTOJI',
                ERR_DUPLICATED_PHONE: 'ERR_BROJ_VEĆ_POSTOJI',
                ERR_DUPLICATED_JMBG: 'ERR_JMBG_VEĆ_POSTOJI',
                ERR_BAD_SESSION_ID: 'ERR_LOŠ_ID_SESIJE',
                ERR_ALREADY_BOOKED: 'ERR_PAŽNJA!_DODAJETE_KORISNIKA_KOJI_JE_VEC_PRIJAVLJEN',
                ERR_BAD_ROLE: 'ERR_POGREŠNA_ROLA_KORISNIKA',
                ERR_BAD_CREDENTIALS: 'ERR_UNELI_STE_POGREŠNE_KREDENCIJALE',
                ERR_ALL_FIELDS_REQUIRED: "ERR_MORATE_POPUNITI_SVA_OBAVEZNA_POLJA",
                ERR_MIN_ONE_SELECTED_VIDEO: "ERR_MORATE_SELEKTOVATI_MINIMALNO_JEDAN_VIDEO",
                ERR_CODE_DUPLICATED_CODE: "ERR_KOD_VEĆ_POSTOJI,_PROBAJTE_DA_GENERIŠETE_NOVI",
                ERR_BAD_USER_ID: "ERR_POGREŠAN_MEJL_KORISNIKA",
                ERR_DUPLICATED_CARD_ID: "ERR_BROJ_KARTICE_VEĆ_POSTOJI",
                ERR_BOOKING_DOES_NOT_EXIST: "ERR_NIJE_MOGUĆE_IZVRŠITI_ZAKAZIVANJE",
                ERR_TRAINING_FILLED: "ERR_TRENING_JE_POPUNJEN",
                ERR_PROMOTION_EXIST_FOR_DATE: "ERR_AKTIVNA_PROMOCIJA_VEĆ_POSTOJI",
                ERR_BAD_DAY: "ERR_POGREŠAN_DAN"
            }
        }
    },
    computed: {
        ...mapGetters("shared", {
            modalStatus: "getModalStatus",
            modalTitle: "getModalTitle"
        }),
        trainingHours() {
            // generating hours from 09 to 22 for each 15 minutes
            // initialize hours and minutes
            let hours = 6;
            let minutes = 0;
            // setting the placeholder
            let output = [{ value: null, title: "Izaberite vreme", disabled: true }];
            // while the hours and minutes are less than the set values, take the hours and multiply by 100 and add to that the minutes
            while (hours < 22 || minutes < 15) {
                let value = hours * 100 + minutes;
                output.push({
                    value: value,
                    title: `${
                        String(hours).length > 1 ? String(hours) : "0" + String(hours)
                    }:${
                        String(minutes).length > 1 ? String(minutes) : "0" + String(minutes)
                    }`,
                });
                minutes += 15;
                if (minutes == 60) {
                    minutes = 0;
                    hours++;
                }
            }
            return output;
        }
    },
    methods: {
        formatTime(val) {
            let value = String(val);
            var len = value.length;
            if (len === 3) {
                value = String(value.slice(0, 1)) + ':' + String(value.slice(-2, value.length))
            } else {
                value = String(value.slice(0, 2)) + ':' + String(value.slice(-2, value.length))
            }
            return value;
        },
        formatDay(value) {
            const days = {
                1: 'Ponedeljak',
                2: 'Utorak',
                3: 'Sreda',
                4: 'Četvrtak',
                5: 'Petak',
                6: 'Subota',
                7: 'Nedelja'
            };
            return days[value];
        },
        handle_error(err) {
            Object.keys(this.errorMessages).forEach((key) => {
                if (key === err.response.data.error) {
                    this.error = this.errorMessages[key]
                }
            });
            let message = this.error
                .replace(/_/g, " ")
                .substr(4, this.error.length)
                .toUpperCase();
            if (message === 'PAŽNJA! DODAJETE KORISNIKA KOJI JE VEC PRIJAVLJEN') {
                this.messageType = 'warning';
            } else {
                this.messageType = 'error';
            }
            this.$toast.open({
                message: message,
                type: this.messageType,
                duration: 5000,
            });
        },
        handle_success_response(message) {
            this.$toast.open({
                message: message,
                type: 'success',
                duration: 5000,
            });
        }
    },
};
