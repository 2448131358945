import http from "../config/http"
export default {
    namespaced: true,
    state: {
        filters: {
            page: 1,
            per_page: 10,
        },
        total: 1,
        pageOptions: [2, 5, 10, 15, 25, 50, 100]
    },
    mutations: {
        setFilters(state, data) {
          state.filters = data;
        }
    },
    getters: {
        getFilters(state) {
          return state.filters;
        },
        getPageOptions(state) {
          return state.pageOptions;
        },
        getTotalPages(state) {
          return state.total;
        },
    }
}