<template>
    <div>
        <router-link
            v-if="role === 'admin'"
            to="/users"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-users mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Korisnici</span>
        </router-link>
        <router-link
            v-if="role === 'admin' || role === 'marketing'"
            to="/event"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-fire-alt mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Aktuelnosti</span>
        </router-link>
        <router-link
            v-if="role === 'admin' || role === 'marketing'"
            to="/blog"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-blog mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Blog</span>
        </router-link>
        <router-link
            v-if="role === 'admin'"
            to="/coaches"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-address-card mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Treneri</span>
        </router-link>
        <router-link
            v-if="role === 'admin'"
            to="/trainings"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-dumbbell mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Treninzi</span>
        </router-link>
        <router-link
            v-if="role === 'admin'"
            to="/clubs" class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
            <span v-if="!navLinks" class="pl-1 bg-nav-link-transition"> Klubovi</span>
        </router-link>
        <router-link
            v-if="role === 'admin'"
            to="/schedule"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-calendar-alt mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Raspored</span>
        </router-link>
        <router-link
            v-if="role === 'admin'"
            to="/promotions"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-bolt ml-1 mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Promocije</span>
        </router-link>
        <router-link
            v-if="role === 'admin' || role === 'accounting' || role.includes('front_desk')"
            to="/booking"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-book-open mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Zakazivanje (T)</span>
        </router-link>
        <router-link
            v-if="role === 'admin' || role === 'accounting' || role.includes('front_desk')"
            to="/promotion-booking"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-book-reader mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Zakazivanje (P)</span>
        </router-link>
        <router-link
            v-if="role === 'admin'"
            to="/online-trainings"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fa fa-globe mr-1" aria-hidden="true"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Online treninzi</span>
        </router-link>
        <router-link
            v-if="role === 'admin' || role === 'accounting'"
            to="/statistic"
            class="text-white exact-active-class hovered-nav-link">
            <i class="fas fa-square-root-alt"></i>
            <span v-if="!navLinks" class="bg-nav-link-transition"> Statistika</span>
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: 'Navigation',
    data() {
        return {
            email: '',
        }
    },
    computed: {
        ...mapGetters('auth', {
            role: 'getRole',
        }),
        ...mapGetters('shared', {
            navLinks: 'getNavLinksStatus',
            sidebar: 'getSidebarStatus',
        })
    }
};
</script>