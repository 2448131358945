import http from "../../config/http"
export default {
    namespaced: true,
    state: {
        videos: [],
    },
    mutations: {
        setVideos(state, data) {
          state.videos = data;
        }
    },
    actions: {
        async fetchVideos(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/video');
            if (data) {
                context.commit('loader/setLoading', false, { root: true });
                context.commit("setVideos", data);
            }
        },
        async createVideo(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/video', data)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE KREIRALI VIDEO', { root: true });
                    context.dispatch("fetchVideos");
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'add_video_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'add_video_modal', show: false}, { root: true });
                })
        },
        async editVideo(context, data) {
            const apiData = {
                name: data.name,
                title: data.title,
                title_eng: data.title_eng,
                description: data.description,
                description_eng: data.description_eng,
                url: data.url,
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/video/${data.id}`, apiData)
                .then((resp) => {
                    context.dispatch("fetchVideos");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI VIDEO', { root: true });
                    context.commit('shared/setModal', {name: 'edit_video_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('shared/setModal', {name: 'edit_video_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
        },
        async deleteVideo(context, videoId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/video/${videoId}`)
                .then((resp) => {
                    context.dispatch("fetchVideos");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI VIDEO', { root: true });
                    context.commit('shared/setModal', {name: 'delete_video_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('shared/setModal', {name: 'delete_video_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
        },
    },
    getters: {
        getVideos(state) {
          return state.videos;
        },
    }
}