import http from "../config/http";
export default {
    namespaced: true,
    state: {
        events: [],
    },
    mutations: {
        setEvents(state, data) {
            state.events = data;
        }
    },
    actions: {
        async fetchEvents(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/event');
            if (data) {
                context.commit('loader/setLoading', false, { root: true });
                // data.sort((a, b) => (a.date_of_creation < b.date_of_creation ) ? 1 : -1);
                data.sort((a, b) => (a.end_date < b.end_date ) ? 1 : -1);
                context.commit("setEvents", data);
            }
        },
        async addNew(context, data) {
            const image_1 = context.rootGetters["upload/getImage_1"];
            const image_2 = context.rootGetters["upload/getImage_2"];
            const apiData = new FormData();
            apiData.append('file', image_1.image);
            apiData.append('home_photo', image_2.image);
            apiData.append('header', data.header);
            apiData.append('header_eng', data.header_eng);
            apiData.append('text', data.text);
            apiData.append('text_eng', data.text_eng);
            apiData.append('start_date', data.start_date);
            apiData.append('end_date', data.end_date);

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
             }

            if (!image_1.image || !image_2.image ) {
                var err = {response: {data: {error: 'ERR_ALL_FIELDS_REQUIRED'}}};
                context.commit('shared/setErrorMessage', err, { root: true });
            } else {
                context.commit('loader/setLoading', true, { root: true });
                await http.post('/bo/event', apiData, config)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE KREIRALI AKTUELNOST', { root: true });
                    context.dispatch('fetchEvents');
                    context.commit('shared/setModal', {name: 'new_event_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_event_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });
            }
        },
        async editEvent(context, data) {
            const image_1 = context.rootGetters["upload/getImage_1"];
            const image_2 = context.rootGetters["upload/getImage_2"];
            const apiData = new FormData();
            if(image_1.image) {
                apiData.append('file', image_1.image)
            }
            if(image_2.image) {
                apiData.append('home_photo', image_2.image)
            }
            apiData.append('header', data.header);
            apiData.append('header_eng', data.header_eng);
            apiData.append('text', data.text);
            apiData.append('text_eng', data.text_eng);
            apiData.append('start_date', data.start_date);
            apiData.append('end_date', data.end_date);

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/event/${data.id}`, apiData, config)
                .then((resp) => {
                    context.dispatch('fetchEvents');
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI AKTUELNOST', { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                    context.commit('shared/setModal', {name: 'edit_event_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_event_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });
        },
        async deleteEvent(context, eventId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/event/${eventId}`)
                .then((resp) => {
                    context.dispatch('fetchEvents');
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI AKTUELNOST', { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
    },
    getters: {
        getEvents(state) {
            return state.events;
        },
    }
}