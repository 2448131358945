export default {
    namespaced: true,
    state: {
        sidebar: true,
        navLinks: true,
        errorMessage: null,
        successMessage: null,
        showModal: false,
        modalTitle: '',
    },
    mutations: {
        setSidebar(state, sidebar) {
            state.sidebar = sidebar;
        },
        setNavLinks(state, navLinks) {
            state.navLinks = navLinks;
        },
        setErrorMessage(state, data) {
          state.errorMessage = data;
        },
        setSuccessMessage(state, message) {
            state.successMessage = message;
        },
        setModal(state, data) {
            state.modalTitle = data.name;
            state.showModal = data.show;
        }
    },
    getters: {
        getSidebarStatus(state) {
          return state.sidebar;
        },
        getNavLinksStatus(state) {
          return state.navLinks;
        },
        getErrorMessage(state) {
          return state.errorMessage;
        },
        getSuccessMessage(state) {
          return state.successMessage;
        },
        getModalTitle(state) {
            return state.modalTitle;
        },
        getModalStatus(state) {
            return state.showModal;
        }
    }
}