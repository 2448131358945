<template>
  <b-card class="mt-4 user-select-none">
    <b-row class="mb-4">
      <b-col class="d-flex justify-content-between">
        <b-button variant="outline-dark shadow" v-b-modal.new_period_modal>
          <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
          Dodaj trening
        </b-button>
        <div class="d-flex justify-content-end">
          <b-button v-if="!editMaxParticipantsOneValueShow" class="mr-4" variant="outline-dark shadow" @click="editMaxParticipantsOneValueShow =! editMaxParticipantsOneValueShow">
            <i class="fa fa-edit mr-1" aria-hidden="true"></i>
            Izmeni maks.br.prijavljenih
          </b-button>
          <b-form-input v-if="editMaxParticipantsOneValueShow"
                        v-model="editMaxParticipantsOneValue"
                        type="number"
                        placeholder="Maks.br.prijav."
                        class="w-25 mr-2"
          ></b-form-input>
          <b-button v-if="editMaxParticipantsOneValueShow"
                    :disabled="!editMaxParticipantsOneValue || editedMaxParticipants.length === 0"
                    class="mr-2" variant="outline-dark shadow"
                    @click="sendChangesMaxParticipantsOneValue()">
            <i class="fa fa-check mr-1" aria-hidden="true"></i>
            Sačuvaj izmene
          </b-button>
          <b-button v-if="editMaxParticipantsOneValueShow" variant="outline-dark shadow" @click="editMaxParticipantsOneValueShow =! editMaxParticipantsOneValueShow , editMaxParticipantsOneValue = null , editedMaxParticipants = []">
            <i class="fas fa-times mr-1" aria-hidden="true"></i>
            Odustani
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="bg-dark mx-0 mb-3 rounded-lg">
      <b-col
        class="text-light py-3 font-weight-bold pointer rounded-lg"
        v-for="(gym, i) in gyms"
        :key="i"
        v-if="gym.id !== null && gym.fitness"
        :class="{ activeGym: gym.id === gym_id}"
        @click="fetchPeriodsForGym({
                            gymId: gym.id,
                            page: page,
                            promotionId: $route.params.id});
                        selectItem(gym.id);">
        {{ gym.name }}
      </b-col>
    </b-row>
    <b-row class="bg-dark mx-0 mb-3 rounded-lg">
      <b-col
        @click="currentDay = null"
        class="text-light py-3 font-weight-bold pointer rounded-lg">
        Poništi dan
      </b-col>
      <b-col
        class="text-light py-3 font-weight-bold pointer rounded-lg"
        :class="{ activeDay: i === currentDay}"
        v-for="(day, i) in days"
        :key="i"
        @click="currentDay = day.value"
        v-if="day.value !== null">
        {{ day.name }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 table-responsive">
        <table class="table table-striped shadow">
          <thead class="bg-dark text-light">
          <tr>
            <th class="text-left">Teretana</th>
            <th>Sala</th>
            <th>Trening</th>
            <th>Dan</th>
            <th>Vreme</th>
            <th class="d-none d-sm-none d-md-none d-lg-table-cell">Maks.br. prijavljenih</th>
            <th class="text-right pr-4">Akcije</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(period, i) in periods"
            v-if="currentDay === null || period.day === currentDay"
          >
            <td class="text-left pl-3">{{ period.gym.name }}</td>
            <td v-if="period.gym_room">{{ period.gym_room.name }}</td>
            <td>{{ period.training.name }}</td>
            <td>{{ period.day | daysFilter }}</td>
            <td>{{ period.time |  formatTime }}</td>
            <td class="d-none d-sm-none d-md-none d-lg-table-cell">

              <span>{{ period.max_participants }}</span>

              <i v-if="editMaxParticipantsOneValueShow" class="fas fa-check-square ml-4 h5 rounded-lg pointer" aria-hidden="true"
                 :class="editedMaxParticipants.includes(period.id) ? 'text-white bg-success p-1 ' : 'text-white bg-dark p-1'"
                 @click="setMarkedMaxParticipants(period.id)"
              ></i>
            </td>
            <td class="text-right">
              <b-button
                v-b-modal.edit_period_modal
                @click="set_edit_data(period)"
                class="mr-3"
                size="sm"
                variant="outline-success shadow">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </b-button>
              <b-button
                v-b-modal.confirm_delete_modal
                @click="period_id = period.id;"
                size="sm"
                class="ml-auto"
                variant="outline-danger shadow">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
        <p v-if="periods.length === 0" class="mt-5">Trenutno nema perioda</p>
      </b-col>
    </b-row>
    <b-modal
      id="new_period_modal"
      ref="new_period_modal"
      title="Dodaj trening"
      centered
      scrollable
    >
      <b-container>
        <b-form>
          <b-form-group label="Teretana">
            <multiselect
              v-model="form.gym"
              :options="gyms"
              select-label="Izaberi"
              selected-label="Izabrano"
              deselect-label="Izbaci"
              :class="!$v.form.gym.required ? 'border-danger' : ''"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Izaberi teretanu">
            </multiselect>
          </b-form-group>
          <b-form-group label="Sala">
            <multiselect
              v-model="form.room"
              :options="rooms"
              select-label="Izaberi"
              selected-label="Izabrano"
              :class="!$v.form.room.required ? 'border-danger' : ''"
              deselect-label="Izbaci"
              :disabled="!form.gym"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Izaberi salu">
            </multiselect>
          </b-form-group>
          <b-form-group label="Trening">
            <multiselect
              v-model="form.training"
              :options="trainings"
              select-label="Izaberi"
              selected-label="Izabrano"
              deselect-label="Izbaci"
              :searchable="true"
              :class="!$v.form.training.required ? 'border-danger' : ''"
              track-by="id"
              label="name"
              placeholder="Izaberi trening">
            </multiselect>
          </b-form-group>
          <b-form-group label="Vreme">
            <b-form-select
              v-model="form.time"
              :options="trainingHours"
              value-field="value"
              text-field="title"
              class="mt-3">
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="input-2" label="Dan">
            <b-form-select
              v-model="form.day"
              :options="days"
              value-field="value"
              text-field="name"
              class="mt-3">
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <date-time-picker
              v-model="form.training_date"
              only-date
              id="11"
              only-date
              color="#787c80"
              button-color="#787c80"
              :class="!$v.form.training_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
              overlay
              format="YYYY-MM-DD"
              label="Datum treninga"
              button-now-translation="Današnji datum"
              position="top"
              light
              no-header
              no-shortcuts
              auto-close
            ></date-time-picker>
          </b-form-group>
          <b-form-group label-for="input-2" label="Maks. prijavljenih">
            <b-form-input
              required
              v-model="form.max_participants"
              :class="!$v.form.max_participants.required ? 'border-danger' : ''"
              placeholder="Unesite maksimalan broj prijavljenih"
            ></b-form-input>
          </b-form-group>
          <div class="mr-auto d-flex pt-2">
            <span class="py-1">Samsung promocija:</span>
            <label class="switch ml-3">
              <input
                v-model="form.samsung_promotion"
                type="checkbox">
              <span class="slider"></span>
            </label>
          </div>
        </b-form>
      </b-container>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button @click="$bvModal.hide('new_period_modal')" size="sm" variant="outline-danger">
          <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
          Otkaži
        </b-button>
        <b-button
          size="sm"
          variant="outline-success"
          :disabled="loading || $v.form.$invalid"
          @click="addNew({form: form, promotionId: $route.params.id, gymId: gym_id, page: page}); selectItem(form.gym.id)">
          <b-spinner v-if="loading" class="mr-1" small></b-spinner>
          <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
          Dodaj
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="edit_period_modal"
      ref="edit_period_modal"
      title="Izmeni trening"
      scrollable
      centered
      @hide="edit_data = {}"
    >
      <b-container>
        <b-form>
          <b-form-group label="Teretana">
            <multiselect
              v-model="edit_data.gym"
              :options="gyms"
              select-label="Izaberi"
              selected-label="Izabrano"
              deselect-label="Izbaci"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Izaberi teretanu">
            </multiselect>
          </b-form-group>
          <b-form-group label="Sala">
            <multiselect
              v-model="edit_data.gym_room"
              :options="rooms"
              select-label="Izaberi"
              selected-label="Izabrano"
              deselect-label="Izbaci"
              :disabled="!edit_data.gym"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Izaberi salu">
            </multiselect>
          </b-form-group>
          <b-form-group label="Trening">
            <multiselect
              v-model="edit_data.training"
              :options="trainings"
              select-label="Izaberi"
              selected-label="Izabrano"
              deselect-label="Izbaci"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Izaberi trening">
            </multiselect>
          </b-form-group>
          <b-form-group label="Vreme">
            <b-form-select
              v-model="edit_data.time"
              :options="trainingHours"
              value-field="value"
              text-field="title"
              class="mt-3">
            </b-form-select>
          </b-form-group>
          <b-form-group label="Dan">
            <b-form-select
              v-model="edit_data.day"
              :options="days"
              value-field="value"
              text-field="name"
              class="mt-3">
            </b-form-select>
          </b-form-group>
          <b-form-group label="Datum treninga">
            <date-time-picker
              v-model="edit_data.training_date"
              only-date
              id="11"
              only-date
              color="#787c80"
              button-color="#787c80"
              :class="!$v.form.training_date.required ? 'border border-danger rounded-lg' : 'border border-dark rounded-lg'"
              overlay
              format="YYYY-MM-DD"
              label="Datum treninga"
              button-now-translation="Današnji datum"
              position="top"
              light
              no-header
              no-shortcuts
              auto-close
            ></date-time-picker>
          </b-form-group>
          <b-form-group label-for="input-2" label="Maks. prijavljenih">
            <b-form-input
              required
              type="number"
              v-model="edit_data.max_participants"
              placeholder="Unesite maksimalan broj prijavljenih"
            ></b-form-input>
          </b-form-group>
          <div class="mr-auto d-flex pt-2">
            <span class="py-1">Samsung promocija:</span>
            <label class="switch ml-3">
              <input
                v-model="edit_data.samsung_promotion"
                type="checkbox">
              <span class="slider"></span>
            </label>
          </div>
        </b-form>
      </b-container>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button @click="$bvModal.hide('edit_period_modal')" size="sm" variant="outline-danger">
          <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
          Otkaži
        </b-button>
        <b-button
          size="sm"
          variant="outline-success"
          :disabled="loading || $v.edit_data.$invalid"
          @click="editPeriod({
                                editData: edit_data,
                                promotionId: $route.params.id,
                                periodId: period_id,
                                gymId: gym_id,
                                page: page
                    })">
          <b-spinner v-if="loading" class="mr-1" small></b-spinner>
          <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
          Izmeni
        </b-button>
      </template>
    </b-modal>

    <confirm-delete-modal
      :modal-name="'confirm_delete_modal'"
      :description="'period'"
      @confirm="deletePeriod({periodId: period_id, promotionId: $route.params.id, gymId: gym_id, page: page})"/>
  </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
import {mapActions, mapGetters, mapMutations} from 'vuex';
export default {
  name: "Schedule",
  data() {
    return {
      form: {
        gym: null,
        room: null,
        training: null,
        time: null,
        day: null,
        training_date: null,
        max_participants: null,
        samsung_promotion: false,
      },
      days: [
        {value: null, name: 'Izaberite dan'},
        {value: 1, name: 'Ponedeljak'},
        {value: 2, name: 'Utorak'},
        {value: 3, name: 'Sreda'},
        {value: 4, name: 'Četvrtak'},
        {value: 5, name: 'Petak'},
        {value: 6, name: 'Subota'},
        {value: 7, name: 'Nedelja'},
      ],
      edit_data: {
        gym: null,
        gym_room: null,
        training: null,
        time: null,
        day: null,
        training_date: null,
        max_participants: null,
      },
      period_id: null,
      currentDay: null,
      date: null,
      gym_id: null,
      // activeItem: null,

      editMaxParticipantsOneValueShow : false,
      editMaxParticipantsOneValue : null,
      editedMaxParticipants : [],

    }
  },
  validationGroup: ['form', 'edit_data'],
  validations: {
    form: {
      gym: {
        required
      },
      room: {
        required
      },
      training: {
        required
      },
      time: {
        required
      },
      day: {
        required
      },
      training_date: {
        required,
      },
      max_participants: {
        required
      },
      samsung_promotion: {
        required
      },
    },
    edit_data: {
      gym: {
        required
      },
      gym_room: {
        required
      },
      training: {
        required
      },
      time: {
        required
      },
      day: {
        required
      },
      max_participants: {
        required
      },
    },
  },
  props: {
    page: {
      type: String,
      required: true
    }
  },
  components: {ConfirmDeleteModal},
  mounted() {
    if (!this.gyms || this.gyms.length < 1) {
      this.fetchGyms();
    }
    this.fetchPeriods({promotionId: this.$route.params.id, page: this.page});
    this.fetchTrainings();
  },
  computed: {
    ...mapGetters("gyms", {
      gyms: "getGyms",
      rooms: "getGymRooms",
    }),
    ...mapGetters("loader", {
      loading: "getLoading"
    }),
    ...mapGetters("schedule", {
      periods: "getPeriods"
    }),
    ...mapGetters("trainings", {
      trainings: "getTrainings"
    })
  },
  methods: {
    ...mapActions("gyms", {
      fetchGyms: "fetchGyms",
      fetchRoomsForGym: "fetchRoomsForGym"
    }),
    ...mapActions("schedule", {
      fetchPeriods: "fetchPeriods",
      fetchPeriodsForGym: "fetchPeriodsForGym",
      addNew: "addNew",
      editPeriod: "editPeriod",
      deletePeriod: "deletePeriod",
      editPeriodsMaxParticipants: "editPeriodsMaxParticipants"
    }),
    ...mapMutations("loader", {
      setLoading: "setLoading"
    }),
    ...mapActions("trainings", {
      fetchTrainings: "fetchTrainings"
    }),
    set_edit_data(data) {
      this.period_id = data.id;
      this.edit_data = data;
    },
    selectItem(gym_id) {
      if (gym_id) {
        this.gym_id = gym_id;
      } else {
        this.gym_id = null;
      }
    },
    clearGymRoom(type) {
      if (type === 'edit') {
        this.edit_data.gym_room = null;
      } else {
        this.form.room = null;
      }

    },
    setMarkedMaxParticipants(period_id) {
      let index = this.editedMaxParticipants.indexOf(period_id)
      if (index > -1){
        this.editedMaxParticipants.splice(index, 1);
      } else {
        this.editedMaxParticipants.push(period_id)
      }
    },

    sendChangesMaxParticipantsOneValue(){
      this.editPeriodsMaxParticipants({
        editData: {
          max_participants :  parseInt(this.editMaxParticipantsOneValue),
          period_ids : this.editedMaxParticipants
        },
        gymId: this.gym_id,
        page: this.page,
        promotionId: this.page === "promotion" ? this.$route.params.id : null,
      });

      this.editMaxParticipantsOneValue = null;
      this.editedMaxParticipants = [];
      this.editMaxParticipantsOneValueShow = !this.editMaxParticipantsOneValueShow;
    }
  },
  watch: {
    "form.gym"(val) {
      if (val) {
        this.fetchRoomsForGym(val.id)
      }
    },
    "edit_data.gym"(val) {
      if (val) {
        this.fetchRoomsForGym(val.id)
      }
    },
  }
}
</script>