import http from "../config/http"
export default {
    namespaced: true,
    state: {
        periods: [],
        page: ''
    },
    mutations: {
        setPeriods(state, data) {
          state.periods = data;
        },
        setPage(state, data) {
            state.page = data;
        }
    },
    actions: {
        async fetchPeriods(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            let url;
            if (data.page === 'promotion') {
                url = `/bo/promotion/${data.promotionId}/period`;
            } else {
                url = '/bo/period';
            }
            const resp = await http.get(url);
            if (resp.data) {
                context.commit('loader/setLoading', false, { root: true });
                resp.data.sort((a, b) => (a.time > b.time) ? 1 : -1);
                context.commit("setPeriods", resp.data);
            }
        },
        async fetchPeriodsForGym(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            let url;
            if (data.page === 'promotion') {
                url = `/bo/promotion/${data.promotionId}/period/gym/${data.gymId}`;
            } else {
                url = `/bo/period/gym/${data.gymId}`;
            }
            const resp = await http.get(url);
            if (resp) {
                context.commit('loader/setLoading', false, { root: true });
                resp.data.sort((a, b) => (a.time > b.time) ? 1 : -1);
                context.commit("setPeriods", resp.data);
                // this.gym_id = gym_id;
            }
        },
        async addNew(context, data) {
            const apiData = {
                gym_id: data.form.gym.id,
                gym_room_id: data.form.room.id,
                training_id: data.form.training.id,
                time: data.form.time,
                day: data.form.day,
                max_participants: data.form.max_participants,
				samsung_promotion: data.form.samsung_promotion,
            };
            if (data.page === 'promotion') apiData.training_date = data.form.training_date;
            context.commit('loader/setLoading', true, { root: true });
            let url;
            if (data.page === 'promotion') {
                url = `/bo/promotion/${data.promotionId}/period`;
            } else {
                url = '/bo/period';
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.post(url, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE DODALI PERIOD U KALENDAR', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_period_modal', show: false}, { root: true });
                    if (data.form.gym.id) {
                        context.dispatch("fetchPeriodsForGym", {gymId: data.form.gym.id, promotionId: data.promotionId, page: data.page});
                    } else {
                        context.dispatch("fetchPeriods", {promotionId: data.promotionId, page: data.page});
                    }
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_period_modal', show: false}, { root: true });
                });
        },
        async editPeriod(context, data) {
            const apiData = {
                gym_id: data.editData.gym.id,
                gym_room_id: data.editData.gym_room.id,
                training_id: data.editData.training.id,
                max_participants: parseInt(data.editData.max_participants),
                time: data.editData.time,
                day: data.editData.day,
				samsung_promotion: data.editData.samsung_promotion
            };
            context.commit('loader/setLoading', true, { root: true });
            let url;
            if (data.page === 'promotion') {
                apiData.training_date = data.editData.training_date;
                url = `/bo/promotion/${data.promotionId}/period/${data.periodId}`;
            } else {
                url = `/bo/period/${data.periodId}`;
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(url, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI PERIOD', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_period_modal', show: false}, { root: true });
                    if (data.gymId) {
                        context.dispatch("fetchPeriodsForGym", {gymId: data.gymId, promotionId: data.promotionId, page: data.page});
                    } else {
                        context.dispatch("fetchPeriods", {promotionId: data.promotionId, page: data.page});
                    }
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_period_modal', show: false}, { root: true });
                });
        },
		async editPeriodsMaxParticipants(context, data) {
            const apiData = data.editData
			
            context.commit('loader/setLoading', true, { root: true });

			let	url = data.page !== "promotion" ? `/bo/period/update/max_participants` : `/bo/promotion/period/update/max_participants`;

            await http.patch(url, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI PERIODE', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    if (data.gymId) {
                        context.dispatch("fetchPeriodsForGym", {gymId: data.gymId, promotionId: data.promotionId, page: data.page});
                    } else {
                        context.dispatch("fetchPeriods", {promotionId: data.promotionId, page: data.page});
                    }
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
        async deletePeriod(context, data) {
            let url;
            if (data.page === 'promotion') {
                url = `/bo/promotion/${data.promotionId}/period/${data.periodId}`;
            } else {
                url = `/bo/period/${data.periodId}`;
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(url)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI PERIOD', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    if (data.gymId) {
                        context.dispatch("fetchPeriodsForGym", {gymId: data.gymId, promotionId: data.promotionId, page: data.page});
                    } else {
                        context.dispatch("fetchPeriods", {promotionId: data.promotionId, page: data.page});
                    }
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                });
        },
    },
    getters: {
        getPeriods(state) {
          return state.periods;
        },
    }
}