export default {
    namespaced: true,
    state: {
        urls: [],
        image_1: null,
        image_2: null,
    },
    mutations: {
        setImage_1(state, e) {
            const file = e.target.files[0];
            state.image_1 = file;
            state.urls[0] = URL.createObjectURL(file);
        },
        setImage_2(state, e) {
            const file = e.target.files[0];
            state.image_2 = file;
            state.urls[1] = URL.createObjectURL(file);
        },
        setEditData(state, data) {
            if (data && data.url_1) {
                state.image_1 = {};
                state.urls[0] = data.url_1
            }
            if (data && data.url_2) {
                state.image_2 = {};
                state.urls[1] = data.url_2
            }
        },
        clearImages(state, data) {
            state.urls = [];
            state.image_1 = data;
            state.image_2 = data;
        }
    },
    getters: {
        getImage_1(state) {
            const data = {
                image: state.image_1,
                url: state.urls[0]
            }
            return data;
        },
        getImage_2(state) {
            const data = {
                image: state.image_2,
                url: state.urls[1]
            }
            return data;
        },
    }
}