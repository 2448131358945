<template>
    <div class="m-3">
        <b-card no-body>
            <b-card-header
                header-bg-variant="dark"
                header-text-variant="light"
                class="d-flex align-items-center p-2 cursor"
            >
                <div class="d-flex align-items-center mr-auto cursor">
                   <span class="pt-2 shadow-lg" v-b-tooltip.hover :title="video.title">
                       {{ video.title.length < 22 ? video.title : video.title.substr(0, 22) + '...' }}
                   </span>
                </div>
                <div v-if="type === 'general'" class="d-flex align-items-center ml-auto cursor">
                    <label class="container-checkbox" v-if="markVideos">
                        <input type="checkbox" :key="video.id" @click="$emit('checked', video.id)">
                        <span class="checkmark"></span>
                    </label>
                    <b-btn size="sm" variant="outline-success" @click="$emit('edit')" class="mr-2">
                        <i class="fa fa-edit"></i>
                    </b-btn>
                    <b-btn size="sm" @click="$emit('delete')" variant="outline-danger" >
                        <i class="fa fa-trash"></i>
                    </b-btn>
                </div>
                <div v-if="type === 'groups'" class="d-flex align-items-center ml-auto cursor">
                    <b-btn size="sm" @click="$emit('remove', video.id)" variant="outline-danger" >
                        <i class="fa fa-trash"></i>
                    </b-btn>
                </div>
            </b-card-header>
            <b-card-body class="bg-primary">
                <iframe width="100%" height="100%"
                        v-if="video.url.length > 10"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        :src="`https://player.vimeo.com/video/${video.url.split('com/')[1].split('/')[0]}`">
                </iframe>
            </b-card-body>
            <div v-if="type === 'general'" class="bg-dark text-light pl-3 py-1">
                <b-row>
                    <small class="ml-1">Ukupno pregleda: {{ video.total_view_count }}</small>
                </b-row>
                <b-row>
                    <small class="ml-1">Ukupno jedinstvenih pregleda: {{ video.unique_view_count }}</small>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "AppVideo",
        props: {
            video: {
                type: Object,
                required: true
            },
            markVideos: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                required: true
            }
         },
    }
</script>