<template>
    <b-card class="mt-4 w-100">
        <b-row class="bg-dark mx-0 mb-1 w-100 rounded" v-if="!role.includes('front_desk')">
            <b-col
                class="text-light font-weight-bold py-3 pointer rounded"
                :class="{ activeGym: i === activeItem}"
                v-for="(gym, i) in gyms"
                :key="i"
                @click="fetchPeriodForGym({gymId: gym.id, date: date, page: page}); selectItem(i, gym.id)"
                v-if="gym.id !== null && gym.fitness">
                {{ gym.name }}
            </b-col>
        </b-row>
        <b-row v-if="!role.includes('front_desk')">
            <b-col class="mt-2 col-sm-6 col-md-2">
                <date-time-picker
                    v-model="date"
                    only-date
                    color="#787c80"
                    button-color="#787c80"
                    overlay
                    class="shadow"
                    format="YYYY-MM-DD"
                    label="Izaberi datum"
                    button-now-translation="Današnji datum"
                    left
                    light
                    no-header
                    no-shortcuts
                    auto-close
                    :max-date="page === 'promotion' ? '' : maxDate"
                ></date-time-picker>
            </b-col>
        </b-row>
        <b-row>
             <b-col class="mt-3">
                <b-table
                   :items="periods"
                   :fields="page === 'booking' ? periodsFields : promotionsFields"
                   :tbody-tr-class="rowClass"
                   hover
                   :busy="loading"
                   responsive
                   class="shadow"
                   head-variant="dark"
                   :striped="true"
                >
                   <template v-slot:cell(gym)="data">
                        {{ data.item.gym.name }}
                    </template>
                    <template v-slot:cell(day)="data">
                        {{ data.item.day | daysFilter}}
                    </template>
                    <template v-slot:cell(time)="data">
                        {{ data.item.time | formatHours }}
                    </template>
                    <template v-slot:cell(training)="data">
                        {{ data.item.training.name }}
                    </template>
                    <template v-slot:cell(number_of_arrived)="data">
                         {{ data.item.number_of_arrived }}
                    </template>
                    <template v-slot:cell(coach)="data">
                         <b-button
                            v-b-modal.coach_info_modal
                            @click="setPeriod(data.item); fetchCoachInfo({date: date, periodId: data.item.id});"
                            :variant="data.item.coach_filled ? 'outline-success shadow-lg' : 'outline-danger shadow-lg'">
                            <i :class="data.item.coach_filled ? 'fa fa-user-check' : 'fa fa-user-times'" aria-hidden="true"></i>
                        </b-button>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            v-b-modal.applicants_list_modal
                            class="mr-3 shadow-lg"
                            @click="fetchApplicantsList({
                                date: date,
                                periodId: data.item.id,
                                page: page
                            });
                            setPeriod(data.item);"
                            variant="outline-dark">
                            <i class="fa fa-list-ol" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
                 <p v-if="periods.length === 0" class="mt-5">Trenutno nema perioda</p>
             </b-col>
        </b-row>
        <b-modal
            id="applicants_list_modal"
            ref="applicants_list_modal"
            hide-footer
            size="lg"
            scrollable
            hide-header
            centered
        >
           <b-container class="d-inline m-0 p-0 h-100">
               <b-row  v-if="!loading">
                   <b-col class="d-flex">
                       <p
                           v-if="period" class="mr-auto">
                           Trening:<span class="font-weight-bold"> {{ period.training.name }}</span>
                       </p>
                   </b-col>
                   <b-col class="d-flex">
                       <p
                           v-if="period"
                           class="ml-auto">
                           Broj prijavljenih: <span class="font-weight-bold">{{applicantsList.length}} / {{ period.max_participants }}</span>
                       </p>
                   </b-col>
               </b-row>
               <b-row>
                   <b-col v-if="applicantsList && applicantsList.length > 0" class="table-wrapper-scroll-y custom-list">
                       <b-table
                           :fields="applicantListFields"
                           :items="applicantsList"
                           :tbody-tr-class="rowClassTwo"
                           small
                           :busy="loading"
                           class="shadow-lg"
                           hover
                           bordered
                           head-variant="dark"
                       >
                           <template v-slot:cell(arrived)="data">
                                <b-button
                                    :variant="data.item.arrived === true ? 'outline-success' : 'outline-danger' "
                                    class="shadow"
                                    @click="userArrivedToTraining({
                                        user: data.item,
                                        periodId: period.id,
                                        gymId: selectedGymId,
                                        date: date,
                                        page: page
                                    })"
                                    size="sm">
                                        <i :class="data.item.arrived === true ? 'fas fa-user-alt' : 'fas fa-user-alt-slash' " aria-hidden="true"></i>
                                </b-button>
                            </template>

                            <template v-slot:cell(actions)="data">
                                <b-button
                                    variant="outline-danger shadow"
                                    @click="$bvModal.show('remove_from_training_list_modal');
                                    applicantId = data.item.id;"
                                    size="sm">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </b-button>
                            </template>
                       </b-table>

                   </b-col>
                   <b-col v-else class="py-5 my-5 text-secondary text-center">Trenutno nema prijavljenih!</b-col>
               </b-row>
               <b-row>
                   <b-col v-if="!loading" class="w-100 mt-4 text-left">
                       <div>
                           <b-button
                               variant="outline-dark shadow"
                               @click="addToTraining = !addToTraining">
                               <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                               Dodaj na trening
                           </b-button>
                       </div>
                       <div class="mt-3" v-if="addToTraining">
                           <div class="my-3">
                               <b-form-checkbox v-model="haveAccount" name="check-button" switch>
                                   Registrovan korisnik
                               </b-form-checkbox>
                           </div>
                           <div>
                               <div v-if="haveAccount">
                                   <b-form-input v-model="searchString" placeholder="Pretražite registrovane korisnike"></b-form-input>
                                   <b-list-group v-if="searchString !== ''" style="max-height: 200px; overflow-y: scroll;">
                                       <b-list-group-item
                                           v-for="(user, i) in users"
                                           :key="user.id"
                                           class="list-item"
                                           @click="setSelectedUser(user); searchString = ''">
                                          {{ i + 1 }}. {{user.first_name}} {{user.last_name}} - {{ user.phone }}
                                       </b-list-group-item>
                                   </b-list-group>
                                   <div v-if="selectedUser" class="mt-2 font-weight-bold w-full border py-2 pl-2">
                                      Selektovan/a:  <span class="mx-2 text-danger">{{selectedUser.first_name}} {{selectedUser.last_name}} - {{ selectedUser.phone }}</span>
                                   </div>
                               </div>
                               <b-row>
                                   <b-col cols="6" class="my-2">
                                       <b-form-input
                                           :class="!$v.form.first_name.required  ? 'border border-danger' : ''"
                                           v-if="!haveAccount"
                                           v-model="form.first_name"
                                           placeholder="Unesite ime">
                                       </b-form-input>
                                   </b-col>
                                   <b-col cols="6" class="my-2">
                                       <b-form-input
                                           v-model="form.last_name"
                                           :class="!$v.form.last_name.required  ? 'border border-danger' : ''"
                                           v-if="!haveAccount"
                                           placeholder="Unesite prezime">
                                       </b-form-input>
                                   </b-col>
                                   <b-col cols="6" class="my-2">
                                       <b-form-input
                                           v-model="form.email.trim()"
                                           :class="!$v.form.email.email  ? 'border border-danger' : ''"
                                           v-if="!haveAccount"
                                           placeholder="Unesite email">
                                       </b-form-input>
                                   </b-col>
                                   <b-col cols="6" class="my-2">
                                       <b-form-input
                                           v-model="form.phone"
                                           :class="!$v.form.phone.required  ? 'border border-danger' : ''"
                                           v-if="!haveAccount"
                                           placeholder="Unesite telefon">
                                       </b-form-input>
                                   </b-col>
                               </b-row>
                           </div>
                           <div class="mt-3 d-flex">
                               <div class="ml-auto">
                                   <b-button
                                       @click="closeAddToTraining()"
                                       variant="outline-danger">
                                       <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                                       Otkaži
                                   </b-button>
                                   <b-button
                                       class="ml-2"
                                       @click="bookTraining({
                                            date: date,
                                            page: page,
                                            haveAccount: haveAccount,
                                            periodId: period.id,
                                            selectedUserId: selectedUser ? selectedUser.id : null,
                                            gymId: selectedGymId,
                                            form: form
                                       }); closeAddToTraining();"
                                       :disabled="loading || $v.form.$invalid && !haveAccount"
                                       variant="outline-success">
                                       <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                                       <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                                       Dodaj
                                   </b-button>
                               </div>
                           </div>
                       </div>
                   </b-col>
               </b-row>
           </b-container>
        </b-modal>
        <b-modal
            id="coach_info_modal"
            ref="coach_info_modal"
            hide-footer
            title="Informacije o treneru"
            centered>
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-form-input
                            v-model="coachInfo.coach"
                            placeholder="Ime i prezime">
                        </b-form-input>
                    </b-form-group>
                    <b-list-group class="p-0 m-0">
                        <b-list-group-item class="d-flex w-100 px-2 py-3">
                            Zamena :
                            <label class="switch ml-auto p-0 m-0">
                                <input
                                    v-model="coachInfo.coach_sub"
                                    type="checkbox">
                                <span class="slider"></span>
                            </label>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex w-100 px-2 py-3">
                            Probio termin :
                            <label class="switch ml-auto p-0 m-0">
                                <input
                                    v-model="coachInfo.finished_on_time"
                                    type="checkbox">
                                <span class="slider"></span>
                            </label>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex w-100 px-2 py-3">
                            Isplaćen :
                            <label class="switch ml-auto p-0 m-0">
                                <input
                                    v-model="coachInfo.paid"
                                    type="checkbox">
                                <span class="slider"></span>
                            </label>
                        </b-list-group-item>
                    </b-list-group>
                </b-form>
                <div class="mt-3 d-flex border-top pt-3">
                    <div class="ml-auto">
                        <b-button
                            @click="$bvModal.hide('coach_info_modal')"
                            variant="outline-danger">
                            <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                            Otkaži
                        </b-button>
                        <b-button
                            class="ml-2"
                            @click="setCoachInfo({form: coachInfo, period: period, date: date, page: page})"
                            :disabled="loading"
                            variant="outline-success">
                            <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                            <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                            Podesi
                        </b-button>
                    </div>
                </div>
            </b-container>
        </b-modal>
        <confirm-delete-modal
            :modal-name="'remove_from_training_list_modal'"
            :description="'korisnika sa liste'"
            @confirm="removeFromTrainingList({
                gymId: period.gym_id,
                applicantId: applicantId,
                date: date,
                page: page,
                periodId: period.id
            })"/>
    </b-card>
</template>

<script>
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {required, email} from 'vuelidate/lib/validators';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import _ from "lodash";
export default {
    name: "Booking",
    data() {
        return {
            date: null,
            todaysDate: null,
            activeItem: 0,
            maxDate: null,
            haveAccount: false,
            searchString: '',
            addToTraining: false,
            period: null,
            applicantId: null,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
            },
            attendance : {
                arrived : false
            },
            periodsFields: [
                { key: "training", label: "Trening", class: "text-left" },
                { key: "day", label: "Dan" },
                { key: "time", label: "Vreme" },
                { key: "number_of_arrived", label: "Prisutno" },
                { key: "number_of_bookings", label: "Br. prijavljenih" },
                { key: "max_participants", label: "Maks. br. prijavljenih" },
                { key: "coach", label: "Trener" },
                { key: "actions", label: "Lista prijavljenih" },
            ],
            promotionsFields: [
                { key: "training", label: "Trening", class: "text-left" },
                { key: "day", label: "Dan" },
                { key: "time", label: "Vreme" },
                { key: "number_of_arrived", label: "Prisutno" },
                { key: "number_of_bookings", label: "Br. prijavljenih" },
                { key: "max_participants", label: "Maks. br. prijavljenih" },
                { key: "actions", label: "Lista prijavljenih" },
            ],
            applicantListFields: [
                { key: 'first_name', label: 'Ime' },
                { key: 'last_name', label: 'Prezime' },
                { key: 'phone', label: 'Telefon' },
                { key: 'arrived', label: 'Prisutnost' , class: 'text-center'},
                { key: 'actions', label: 'Akcije', class: 'text-center' },
            ]
        }
    },
    validationGroup: ['form'],
    validations: {
        form: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            email: {
                email,
            },
            phone: {
                required,
            },
        }
    },
    props: {
        page: {
            type: String,
            required: true
        }
    },
    components: {ConfirmDeleteModal},
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters("gyms", {
            gyms: "getGyms",
        }),
        ...mapGetters('auth', {
            role: 'getRole',
        }),
        ...mapGetters('users', {
            users: 'getUsers',
        }),
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("booking", {
            periods: "getPeriods",
            applicantsList: "getApplicantsList",
            selectedGymId: "getSelectedGymId",
            selectedUser: "getSelectedUser",
            coachInfo: "getCoachInfo",
        })
    },
    methods: {
        ...mapActions("gyms", {
            fetchGyms: "fetchGyms"
        }),
        ...mapActions("users", {
            fetchUsers: "fetchUserByName"
        }),
        ...mapActions("booking", {
            fetchPeriodForGym: "fetchPeriodForGym",
            fetchApplicantsList: "fetchApplicantsList",
            bookTraining: "bookTraining",
            removeFromTrainingList: "removeFromTrainingList",
            userArrivedToTraining: "userArrivedToTraining",
            fetchCoachInfo: "fetchCoachInfo",
            setCoachInfo: "setCoachInfo",
        }),
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapMutations("booking", {
            setGymId: "setGymId",
            setSelectedUser: "setSelectedUser",
        }),
        init() {
            this.fetchGyms();
            if (this.role.includes('front_desk')) {
                this.fetchPeriodForGym({gymId: null, date: this.date, page: ''});
            } else {
                this.setGymId(this.gyms[0].id);
                this.fetchPeriodForGym({gymId: this.gyms[0].id, date: this.date, page: this.page});
            }
            this.getTodayDate();
        },
        getTodayDate() {
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            month = String(month);
            if (month.length === 1) {
                month = '0' + month;
            }
            day = String(day);
            if (day.length === 1) {
                day = '0' + day;
            }
            this.maxDate = year + '-' + month + '-' + day;
            this.todaysDate = year + '-' + month + '-' + day;
        },
        selectItem(i, gymId) {
            this.activeItem = i;
            this.setGymId(gymId);
        },
        setPeriod(data) {
            this.period = data;
        },
        closeAddToTraining() {
            this.addToTraining = false;
            this.selectedUser = null;
            this.firstNameState = null;
            this.lastNameState = null;
            this.phoneState = null;
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
            };
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.number_of_bookings < item.max_participants) return 'table-success'
            if (item.number_of_bookings >= item.max_participants) return 'table-danger'
        },
        rowClassTwo(item, type) {
            if(!item.arrived){
                return 'table-danger'
            }
            return 'table-success'
        },
    },
    watch: {
      gyms(val) {
          if (val !== null) {
              this.setGymId(this.gyms[0].id);
              this.fetchPeriodForGym({gymId: this.gyms[0].id, date: this.date, page: this.page});
          }
      },
      date(val) {
          this.fetchPeriodForGym({gymId: this.selectedGymId, date: val, page: this.page});
      },
      searchString: _.debounce(function() {
          if (this.searchString !== '') {
            this.fetchUsers(this.searchString);
          }
      }, 300),
    },
}
</script>