import Vue from 'vue';
import Vuex from 'vuex';
import gyms from './gym';
import auth from './auth';
import loader from './loader';
import shared from './shared';
import users from './users';
import events from './events';
import upload from './upload';
import blog from './blog';
import coaches from './coaches';
import trainings from './trainings';
import schedule from './schedule';
import promotions from './promotions';
import booking from './booking';
import statistic from './statistic';
import codes from './onlineTrainings/codes';
import groups from './onlineTrainings/groups';
import videos from './onlineTrainings/videos';
import pagination from './pagination';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        users,
        gyms,
        events,
        blog,
        coaches,
        trainings,
        promotions,
        schedule,
        booking,
        codes,
        groups,
        videos,
        statistic,
        loader,
        upload,
        pagination,
        shared,
    },
    plugins: [
        createPersistedState({
            paths: [
                'auth',
                'users',
                'coaches',
                'videos',
            ],
        }),
    ],
});
