import http from "../config/http"
export default {
    namespaced: true,
    state: {
        coaches: [],
    },
    mutations: {
        setCoaches(state, data) {
          state.coaches = data;
        }
    },
    actions: {
        async fetchCoaches(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/trainer');
            if (data) {
                context.commit('loader/setLoading', false, { root: true });
                data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ) ? 1 : -1);
                context.commit("setCoaches", data);
            }
        },
        async addNew(context, data) {
            const image = context.rootGetters["upload/getImage_1"];
            const apiData = new FormData();
            apiData.append('file', image.image);
            apiData.append('name', data.name);
            apiData.append('description', data.description);
            apiData.append('description_eng', data.description_eng);

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/trainer', apiData, config)
                .then((resp) => {
                    context.dispatch("fetchCoaches");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE DODALI TRENERA', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_coach_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_coach_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });
        },
        async editCoach(context, data) {
            const image = context.rootGetters["upload/getImage_1"];
            const apiData = new FormData();
            if(image.image) {
                apiData.append('file', image.image)
            }
            apiData.append('name', data.name);
            apiData.append('description', data.description);
            apiData.append('description_eng', data.description_eng);

            let config = {
                  header : {
                    'Content-Type' : 'multipart/form-data'
                  }
             }

            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/trainer/${data.id}`, apiData, config)
                .then((resp) => {
                    context.dispatch("fetchCoaches");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI PODATKE O TRENERU', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_coach_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_coach_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });
        },
        async deleteCoach(context, coachId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/trainer/${coachId}`)
                .then((resp) => {
                    context.dispatch("fetchCoaches");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI TRENERA', { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                });
        },
    },
    getters: {
        getCoaches(state) {
          return state.coaches;
        },
    }
}