import http from "../../config/http"
export default {
    namespaced: true,
    state: {
        groups: [],
        allCodes: [],
        copyOfAllCodes: [],
        selectedCodes: [],
        copyOfAllSelectedCodes: [],
        groupVideos: [],
        selectedVideos: [],
        markVideos: false,
    },
    mutations: {
        setGroups(state, data) {
          state.groups = data;
        },
        setGroupVideos(state, data) {
          state.groupVideos = data;
        },
        setSelectedVideos(state, data) {
            state.selectedVideos = data;
        },
        setMarkVideos(state, data) {
            state.markVideos = data;
        }
    },
    actions: {
        async fetchGroups(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/group');
            if (data) {
                context.commit('setGroups', data);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
        async createGroup(context, data) {
            const apiData = {
                name: data.name,
            };
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/group', apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE KREIRALI GRUPU', { root: true });
                    context.dispatch("fetchGroups");
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'create_group_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'create_group_modal', show: false}, { root: true });
                })
        },
         async editGroup(context, data) {
            let code_ids = [];
            // data.codes.forEach((item, i) => {
            //     code_ids.push(item.id);
            // });
            const apiData = {
                name: data.form.name,
                code_ids: data.selectedCodeIds,
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/group/${data.form.id}/code`, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI GRUPU', { root: true });
                    context.dispatch("fetchGroups");
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_group_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_group_modal', show: false}, { root: true });
                })
        },
        async deleteGroup(context, groupId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/group/${groupId}`)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI GRUPU', { root: true });
                    context.dispatch("fetchGroups");
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'delete_group_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'delete_group_modal', show: false}, { root: true });
                })
        },
        async fetchVideosForGroup(context, group) {
            const { data } = await http.get(`/bo/group/${group.id}/video`);
            context.commit("setGroupVideos", data);
            console.log('group videos from vuex')
        },
        async assignVideosToGroup(context, data) {
            const apiData = [];
            data.videos.forEach((item, i) => {
                if (item !== null) {
                    apiData.push(item);
                }
            })
            if (apiData.length > 0) {
                context.commit('loader/setLoading', true, { root: true });
                await http.patch(`/bo/group/${data.selectedGroup.id}/video`, {video_ids: apiData})
                    .then((resp) => {
                        context.commit('shared/setSuccessMessage', 'USPEŠNO STE DODELILI VIDEO MATERIJAL GRUPI', { root: true });
                        context.commit('shared/setModal', {name: 'assign_video_modal', show: false}, { root: true });
                        context.commit('loader/setLoading', false, { root: true });
                    })
                    .catch((err) => {
                        context.commit('shared/setErrorMessage', err, { root: true });
                        context.commit('shared/setModal', {name: 'assign_video_modal', show: false}, { root: true });
                        context.commit('loader/setLoading', false, { root: true });
                    })
            } else {
                var err = {response: {data: {error: 'ERR_MIN_ONE_SELECTED_VIDEO'}}};
                context.commit('shared/setErrorMessage', err, { root: true });
            }
        },
        async removeVideoFromGroup(context, data) {
            await http.delete(`/bo/group/${data.groupId}/video/${data.videoId}`)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE UKLONILI VIDEO IZ GRUPE', { root: true });
                    context.dispatch("fetchVideosForGroup", {id: data.groupId});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                })
        },
    },
    getters: {
        getGroups(state) {
          return state.groups;
        },
        getGroupVideos(state) {
            return state.groupVideos;
        },
        getSelectedVideos(state) {
            return state.selectedVideos;
        },
        getMarkVideos(state) {
            return state.markVideos;
        }
    }
}