import http from "../config/http";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
        role: localStorage.getItem('role') || '',
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', `${token}`);
        },
        setRole(state, role) {
            state.role = role;
            localStorage.setItem('role', `${role}`);
        },
        successLogin(state, sessionId) {
            http.defaults.headers.common['session_id'] = `${sessionId}`;
            router.push('/');
        },
        logoutUser(state) {
            delete http.defaults.headers.common['session_id'];
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            router.push('/login');
        },
    },
    actions: {
        async login(context, payload) {
            await http.post(`bo/user/login`, payload)
                .then((data) => {
                    context.commit('setRole', data.data.role);
                    context.commit('setToken', data.data.session_id);
                    context.commit('successLogin', data.data.session_id);
                })
                .catch ((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('logoutUser');
                })
        },
        logout(context, payload) {
            context.commit('setToken', null);
            context.commit('setRole', null);
            context.commit('logoutUser');
            context.commit('loader/setLoading', false, { root: true });
        },
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getRole(state) {
            return state.role;
        },
    }
}