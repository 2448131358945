import http from "../config/http";

export default {
    namespaced: true,
    state: {
        users: [],
        userCount: 0,
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        setCount(state, count) {
            state.userCount = count;
        }
    },
    actions: {
        async fetchUsers(context, apiData) {
            let url = '/bo/user';
            if (apiData.searchString !== '') {
                url = `/bo/user?name=${apiData.searchString}`;
            }
            if (apiData.showUnregistered === false) {
                url += apiData.searchString ? '&role=user' : '?role=user'

				// url += `&system_connected=${apiData.showConnectedToSystem}` + `&subscription=${apiData.showAcceptedSubscription}`;
				url += `&system_connected=${apiData.showConnectedToSystem}`
				
            }
            if (apiData.showUnregistered === true) {
                url += apiData.searchString ? '&role=guest' : '?role=guest';
            }

            context.commit('loader/setLoading', true, { root: true });
            const {data} = await http.get(url, {params: {...apiData.filters}});
            if (data) {
                context.commit('loader/setLoading', false, { root: true });
                context.commit("setUsers", data.users);
                context.commit("setCount", data.total);
            }
        },
        async fetchUserByName(context, searchString) {
            const { data } = await http.get(`/bo/user/search/${searchString}`);
            context.commit("setUsers", data);
        },
        async editUser(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/user/${data.userId}`, data.apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI PODATKE O KORISNIKU', { root: true });
                    context.dispatch("fetchUsers", {searchString: data.searchString, showUnregistered: data.showUnregistered, filters: data.filters});
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_user_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_user_modal', show: false}, { root: true });
                })
        },
        async deleteUser(context, data) {
            await http.delete(`/bo/user/${data.userId}`)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI KORINSIKA', { root: true });
                    context.commit('shared/setModal', {name: 'remove_user_modal', show: false}, { root: true });
                    context.dispatch("fetchUsers", {searchString: data.searchString, showUnregistered: data.showUnregistered,showConnectedToSystem: data.showConnectedToSystem, filters: data.filters});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'remove_user_modal', show: false}, { root: true });
                })
        },
    },
    getters: {
        getUsers(state) {
          return state.users;
        },
        getCount(state) {
            return state.userCount;
        }
    }
}