const formatDays = (value) => {
    const days = {
        1: 'Ponedeljak',
        2: 'Utorak',
        3: 'Sreda',
        4: 'Četvrtak',
        5: 'Petak',
        6: 'Subota',
        7: 'Nedelja'
    };
    return days[value];
}

const cutLongText = (value) => {
    if (value.length > 20) {
        return value.substr(0, 13) + '...';
    } else {
        return value;
    }
}

const formatTime = (val) => {
    let value = String(val);
    var len = value.length;
    if (len === 3) {
        value = String(value.slice(0, 1)) + ':' + String(value.slice(-2, value.length))
    } else {
        value = String(value.slice(0, 2)) + ':' + String(value.slice(-2, value.length))
    }
    return value;
}

const formatHours = (value) => {
    const hours = {
        900: '09:00',
        915: '09:15',
        930: '09:30',
        945: '09:45',
        1000: '10:00',
        1015: '10:15',
        1030: '10:30',
        1045: '10:45',
        1100: '11:00',
        1115: '11:15',
        1130: '11:30',
        1145: '11:45',
        1200: '12:00',
        1215: '12:15',
        1230: '12:30',
        1245: '12:45',
        1300: '13:00',
        1315: '13:15',
        1330: '13:30',
        1345: '13:45',
        1400: '14:00',
        1415: '14:15',
        1430: '14:30',
        1445: '14:45',
        1500: '15:00',
        1515: '15:15',
        1530: '15:30',
        1545: '15:45',
        1600: '16:00',
        1615: '16:15',
        1630: '16:30',
        1645: '16:45',
        1700: '17:00',
        1715: '17:15',
        1730: '17:30',
        1745: '17:45',
        1800: '18:00',
        1815: '18:15',
        1830: '18:30',
        1845: '18:45',
        1900: '19:00',
        1915: '19:15',
        1930: '19:30',
        1945: '19:45',
        2000: '20:00',
        2015: '20:15',
        2030: '20:30',
        2045: '20:45',
        2100: '21:00',
        2115: '21:15',
        2130: '21:30',
        2145: '21:45',
        2200: '22:00',
    };
    return hours[value];
}

const filters = {formatDays: formatDays, formatHours: formatHours, cutLongText: cutLongText, formatTime: formatTime};
export default filters;