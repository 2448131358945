<template>
    <app-booking :page="'booking'"></app-booking>
</template>

<script>
import AppBooking from '../components/AppBooking';
import {mapMutations} from "vuex";
export default {
    name: "Booking",
    components: {AppBooking},
    mounted() {
        this.setPage('booking');
    },
    methods: {
        ...mapMutations("booking", {
            setPage: "setPage"
        })
    }
}
</script>