import http from "../config/http";

export default {
    namespaced: true,
    state: {
        gyms: [],
        rooms: [],
    },
    mutations: {
        setGyms(state, data) {
            state.gyms = data;
        },
        setGymRooms(state, data) {
            state.rooms = data;
        }
    },
    actions: {
        // CRUD for Clubs/Gyms
        async fetchGyms(context, data) {
            const role = context.rootGetters["auth/getRole"];
            if (!role.includes('front_desk') || !role.includes('marketing')){
                context.commit('loader/setLoading', true, { root: true });
                const res = await http.get('/bo/gym');
                if (res) {
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit("setGyms", res.data);
                }
            }
        },
        async addNew(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/gym', data)
                .then((resp) => {
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE DODALI LOKACIJU', { root: true });
                    context.dispatch('fetchGyms');
                    context.commit('shared/setModal', {name: 'new_location_modal', show: false}, { root: true });

                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_location_modal', show: false}, { root: true });
                });
        },
        async editLocation(context, data) {
            const apiData = {
                name: data.name,
                address: data.address,
                phone: data.phone,
                phone_2: data.phone_2,
                weekdays: data.weekdays,
                saturday: data.saturday,
                sunday: data.sunday,
                gym: data.gym,
                fitness: data.fitness,
            };
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/gym/${data.id}`, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI PODATKE', { root: true });
                    context.dispatch('fetchGyms');
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_location_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_location_modal', show: false}, { root: true });
                });
        },
        async deleteLocation(context, clubId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/gym/${clubId}`)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI LOKACIJU', { root: true });
                    context.dispatch('fetchGyms');
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                });
        },

        // CRUD for gym rooms
        async fetchRoomsForGym(context, gymId) {
            const { data } = await http.get(`/bo/gym/${gymId}/gym_room`);
            context.commit("setGymRooms", data);
        },
        async addNewRoom(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.post(`/bo/gym/${data.clubId}/gym_room`, {name: data.roomTitle})
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE DODALI SALU', { root: true });
                    context.dispatch('fetchRoomsForGym', data.clubId);
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
        async editRoom(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/gym/gym_room/${data.roomId}`, {name: data.roomTitle})
                .then((resp) => {
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI SALU', { root: true });
                    context.dispatch('fetchRoomsForGym', data.gymId);
                    context.commit('shared/setModal', {name: 'edit_room_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
        async deleteRoom(context, data) {
            await http.delete(`/bo/gym/gym_room/${data.roomId}`)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI SALU', { root: true });
                    context.dispatch('fetchRoomsForGym', data.gymId);
                    context.commit('shared/setModal', {name: 'confirm_delete_room_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                });
        },
    },
    getters: {
        getGyms(state) {
          return state.gyms;
        },
        getGymRooms(state) {
          return state.rooms;
        },
    }
}