<template>
    <b-row id="app" class="w-100">
        <div v-if="$route.name !== 'Login'" class="top-bar-wrapper">
            <top-bar></top-bar>
        </div>
        <div v-if="$route.name !== 'Login'" id="mySidebar" class="sidebar">
            <navigation></navigation>
            <div @click="toggleSidebar" class="text-light mt-0 mb-2 pt-2 pb-2 font-weight-bold border-top btn-collapse">
                <i class="fas mr-1" :class="!sidebar ? 'fa-angle-double-left' : 'fa-angle-double-right'" aria-hidden="true"></i>
            </div>
        </div>
        <div id="main">
            <router-view class="zoom-animation"/>
            <app-loader v-if="loading"></app-loader>
        </div>
    </b-row>
</template>

<script>
import Navigation from "./components/shared/Navigation";
import TopBar from "@/components/shared/TopBar";
import AppLoader from "@/components/AppLoader";

import { mapMutations, mapGetters } from "vuex";
export default {
    components: {Navigation, TopBar, AppLoader},
    computed: {
        ...mapGetters("shared", {
            sidebar: "getSidebarStatus",
            navLinks: "getNavLinksStatus",
            errorMessage: "getErrorMessage",
            modalStatus: "getModalStatus",
            modalTitle: "getModalTitle",
            successMessage: "getSuccessMessage",
        }),
        ...mapGetters("loader", {
            loading: "getLoading",
        }),
    },
    mounted() {
        this.toggleSidebar();
    },
    methods: {
        ...mapMutations("shared", {
            setSidebar: "setSidebar",
            setNavLinks: "setNavLinks",
            setModal: "setModal",
            setSuccessMessage: "setSuccessMessage",
            setErrorMessage: "setErrorMessage",
        }),
        toggleSidebar() {
            if (this.$route.name !== 'Login') {
                var sidebarNav = document.getElementById("mySidebar");
                var main = document.getElementById("main");
                this.setSidebar(!this.sidebar);
                if (window.innerWidth < 1024) {
                    if (this.sidebar === true) {
                        this.setNavLinks(!this.navLinks);
                        sidebarNav.style.minWidth = "40px";
                    } else {
                        this.setNavLinks(!this.navLinks);
                        sidebarNav.style.minWidth = "13%";
                    }
                } else {
                    if (this.sidebar === true) {
                        sidebarNav.style.minWidth = "40px";
                        main.style.paddingLeft = '60px';
                        main.style.width = "99%";
                        this.setNavLinks(!this.navLinks);
                    } else {
                        sidebarNav.style.minWidth = "13%";
                        main.style.width = "86.6%";
                        main.style.paddingLeft = '20px';
                        setTimeout(() => this.setNavLinks(!this.navLinks), 500);
                    }
                }
            }

        }
    },
    watch: {
        successMessage(val) {
            if (val !== null) {
                this.handle_success_response(val);
                this.$nextTick(() => {
                    this.setSuccessMessage(null);
                });
            }
        },
        errorMessage(val) {
            if (val !== null) {
                this.handle_error(val);
            }
            this.$nextTick(() => {
                this.setErrorMessage(null);
            });
        },
        modalStatus(val) {
            if (val !== null) {
                if (val === true) {
                    this.$bvModal.show(this.modalTitle);
                } else if (val === false) {
                    this.$bvModal.hide(this.modalTitle);
                }
                this.$nextTick(() => {
                    this.setModal({name: null, show: null});
                });
            }
        },
        modalTitle(val) {
            if (val !== null) {
                if (this.modalStatus === true) {
                    this.$bvModal.show(val);
                } else if (this.modalStatus === false) {
                    this.$bvModal.hide(val);
                }
                this.$nextTick(() => {
                    this.setModal({name: null, show: null});
                });
            }
        }
    }
}
</script>

<style lang="scss">

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid"; // fas
@import "~@fortawesome/fontawesome-free/scss/regular"; // far
@import "~@fortawesome/fontawesome-free/scss/brands"; // fab

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
}
</style>