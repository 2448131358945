import http from "../config/http"
export default {
    namespaced: true,
    state: {
        selectedGymId: null,
        selectedUser: null,
        page: '',
        periods: [],
        applicantsList: [],
        coachInfo: {},
    },
    mutations: {
        setPeriods(state, data) {
          state.periods = data;
        },
        setApplicantsList(state, data) {
          state.applicantsList = data;
        },
        setPage(state, data) {
            state.page = data;
        },
        setGymId(state, data) {
            state.selectedGymId = data;
        },
        setSelectedUser(state, data) {
            state.selectedUser = data;
        },
        setCoachInfo(state, data) {
            state.coachInfo = data;
        }
    },
    actions: {
        async fetchPeriodForGym(context, data) {
            context.commit("setPeriods", []);
            let url;
            const role = context.rootGetters["auth/getRole"];
            if (!role.includes('front_desk')) {
                context.commit("setGymId", data.gymId);
                if (data.page !== 'promotion') {
                    if (data.date !== null) {
                        url = `/bo/period/gym/${data.gymId}/date?training_date=${data.date}`;
                    } else {
                        url = `/bo/period/gym/${data.gymId}/date`;
                    }
                } else {
                    if (data.date !== null) {
                        url = `/bo/promotion/period/gym/${data.gymId}/date?training_date=${data.date}`;
                    } else {
                        url = `/bo/promotion/period/gym/${data.gymId}/date`;
                    }
                }
                context.commit('loader/setLoading', true, { root: true });
                const resp = await http.get(url);
                if (resp) {
                    context.commit('loader/setLoading', false, { root: true });
                    resp.data.sort((a, b) => (a.time > b.time) ? 1 : -1);
                    context.commit("setPeriods", resp.data);
                }
            } else {
                if (data.page === 'booking') {
                    url = '/bo/period/gym/front_desk';
                } else {
                    url ='/bo/promotion/period/gym/front_desk';
                }
                context.commit('loader/setLoading', true, { root: true });
                const resp = await http.get(url);
                if (resp) {
                    resp.data.sort((a, b) => (a.time > b.time) ? 1 : -1);
                    context.commit("setPeriods", resp.data);
                    context.commit('loader/setLoading', false, { root: true });
                }
            }
        },
        async fetchApplicantsList(context, data) {
            let url = '';
            if (data.page === 'booking') {
                if (data.date === null || data.date === '') {
                    url = `/bo/period/${data.periodId}/booking`;
                } else {
                    url = `/bo/period/${data.periodId}/booking?training_date=${data.date}`;
                }
            } else {
                if (data.date === null || data.date === '') {
                    url = `/bo/promotion/period/${data.periodId}/booking`;
                } else {
                    url = `/bo/promotion/period/${data.periodId}/booking?training_date=${data.date}`;
                }
            }
            context.commit('loader/setLoading', true, { root: true });
            const resp = await http.get(url);
            if (resp.data) {
                resp.data.sort((a, b) => (a.first_name.toLowerCase() > b.first_name.toLowerCase()) ? 1 : -1);
                context.commit("setApplicantsList", resp.data);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
        async bookTraining(context, data) {
            let url = '';
            //book training if user have account
            if (data.haveAccount) {
                if (data.page === 'booking') {
                    if (data.date !== null) {
                        url = `/bo/period/${data.periodId}/user/${data.selectedUserId}/booking?training_date=${data.date}`;
                    } else {
                        url = `/bo/period/${data.periodId}/user/${data.selectedUserId}/booking`;
                    }
                } else {
                    if (data.date !== null) {
                        url = `/bo/promotion/period/${data.periodId}/user/${data.selectedUserId}/booking?training_date=${data.date}`;
                    } else {
                        url = `/bo/promotion/period/${data.periodId}/user/${data.selectedUserId}/booking`;
                    }
                }
                context.commit('loader/setLoading', true, { root: true });
                await http.post(url)
                    .then((resp) => {
                        context.commit("setSelectedUser", null);
                        context.commit('shared/setSuccessMessage', 'USPEŠNO DODAT/A NA TRENING', { root: true });
                        context.dispatch("fetchPeriodForGym", {gymId: data.gymId, date: data.date, page: data.page});
                        context.dispatch("fetchApplicantsList", {periodId: data.periodId, date: data.date, page: data.page});
                        context.commit('loader/setLoading', false, { root: true });
                    })
                    .catch((err) => {
                        context.commit("setSelectedUser", null);
                        context.commit('shared/setErrorMessage', err, { root: true });
                        context.commit('loader/setLoading', false, { root: true });
                    });
            }
            // validation form and book training if user don't have account
            if (!data.haveAccount) {
                if (data.page === 'booking') {
                    if (data.date !== null) {
                        url = `/bo/period/${data.periodId}/booking/guest?training_date=${data.date}`;
                    } else {
                        url = `/bo/period/${data.periodId}/booking/guest`;
                    }
                } else {
                    if (data.date !== null) {
                        url = `/bo/promotion/period/${data.periodId}/booking/guest?training_date=${data.date}`;
                    } else {
                        url = `/bo/promotion/period/${data.periodId}/booking/guest`;
                    }
                }
                context.commit('loader/setLoading', true, { root: true });
                await http.post(url, data.form)
                    .then((resp) => {
                        context.commit('shared/setSuccessMessage', 'USPEŠNO DODAT/A NA TRENING', { root: true });
                        context.commit("setSelectedUser", null);
                        context.dispatch("fetchPeriodForGym", {gymId: data.gymId, date: data.date, page: data.page});
                        context.dispatch("fetchApplicantsList", {periodId: data.periodId, date: data.date, page: data.page});
                        context.commit('loader/setLoading', false, { root: true });
                    })
                    .catch((err) => {
                        context.commit('shared/setErrorMessage', err, { root: true });
                        context.commit("setSelectedUser", null);
                        context.commit('loader/setLoading', false, { root: true });
                    });
            }
        },
        async removeFromTrainingList(context, data) {
            let url = '';
            if (context.state.page === 'booking') {
                if (data.date !== null) {
                    url = `/bo/period/${data.periodId}/user/${data.applicantId}/booking?training_date=${data.date}`
                } else {
                    url = `/bo/period/${data.periodId}/user/${data.applicantId}/booking`
                }
            } else {
                if (data.date !== null) {
                    url = `/bo/promotion/period/${data.periodId}/user/${data.applicantId}/booking?training_date=${data.date}`
                } else {
                    url = `/bo/promotion/period/${data.periodId}/user/${data.applicantId}/booking`
                }
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(url)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO UKLONJEN/A SA LISTE', { root: true });
                    // this.closeAddToTraining();
                    context.commit('loader/setLoading', false, { root: true });
                    context.dispatch("fetchPeriodForGym", {gymId: data.gymId, date: data.date, page: data.page});
                    context.dispatch("fetchApplicantsList", {periodId: data.periodId, date: data.date, page: data.page});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
        async userArrivedToTraining(context, data){
            let url = '';
            if (data.page === 'booking') {
                url = url = `/bo/period/${data.periodId}/user/${data.user.id}/booking/arrived`;
            } else {
                url = `/bo/promotion/period/${data.periodId}/user/${data.user.id}/booking/arrived`;
            }

            if (data.date !== null) {
                url += `?training_date=${data.date}`
            }

            // if (data.page === 'booking') {
            //     if (data.date !== null) {
            //         url = `/bo/period/${data.periodId}/user/${data.user.id}/booking/arrived?training_date=${data.date}`
            //     } else {
            //         url = `/bo/period/${data.periodId}/user/${data.user.id}/booking/arrived`
            //     }
            // } else {
            //     if (data.date !== null) {
            //         url = `/bo/period/${data.periodId}/user/${data.user.id}/booking/arrived?training_date=${data.date}`
            //     } else {
            //         url = `/bo/period/${data.periodId}/user/${data.user.id}/booking/arrived`
            //     }
            // }
            const attendance = {arrived: !data.user.arrived};
            context.commit('loader/setLoading', true, { root: true });
            await http.post(url, attendance)
                .then((resp) => {
                    context.dispatch("fetchPeriodForGym", {gymId: data.gymId, date: data.date, page: data.page});
                    context.dispatch("fetchApplicantsList", {periodId: data.periodId, date: data.date, page: data.page});
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
        async fetchCoachInfo(context, data) {
            let url = '';
            if (data.date !== null) {
                url = `/bo/period/${data.periodId}/coach?training_date=${data.date}`;
            } else {
                url = `/bo/period/${data.periodId}/coach`;
            }
            await http.get(url)
                .then((resp) => {
                    context.commit("setCoachInfo", resp.data);
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('shared/setModal', {name: 'coach_info_modal', show: false}, { root: true });
                });
        },
        async setCoachInfo(context, data) {
            const apiData = {
                coach: data.form.coach,
                finished_on_time: data.form.finished_on_time,
                paid: data.form.paid,
                coach_sub: data.form.coach_sub,

            }
            let url = '';
            if (data.date !== null) {
                url = `/bo/period/${data.period.id}/coach?training_date=${data.date}`;
            } else {
                url = `/bo/period/${data.period.id}/coach`;
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(url, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE SETOVALI INFORMACIJE O TRENERU', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'coach_info_modal', show: false}, { root: true });
                    context.dispatch("fetchPeriodForGym", {gymId: data.period.gym_id, date: data.date, page: data.page});
                })
                .catch((err) => {
                    context.commit('shared/setModal', {name: 'coach_info_modal', show: false}, { root: true });
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
    },
    getters: {
        getPeriods(state) {
          return state.periods;
        },
        getApplicantsList(state) {
          return state.applicantsList;
        },
        getSelectedGymId(state) {
          return state.selectedGymId;
        },
        getSelectedUser(state) {
          return state.selectedUser;
        },
        getCoachInfo(state) {
          return state.coachInfo;
        },
    }
}