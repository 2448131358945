<template>
    <div v-else class="border  mt-4 px-2 mx-0">
        <b-row class="mb-4 mt-4">
            <b-col class="text-left">
                <b-button variant="outline-dark shadow" v-b-modal.new_training_modal>
                    <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                    Dodaj trening
                </b-button>
            </b-col>
        </b-row>
        <b-row>
             <b-col >
                <b-table
                   :items="trainings"
                   :fields="fields"
                   hover
                   :busy="loading"
                   responsive
                   class="shadow"
                   head-variant="dark"
                   :striped="true"
                >
                    <template v-slot:cell(actions)="data">
                        <b-row>
                            <b-col>
                                <b-button
                                    v-b-modal.edit_training_modal
                                    @click="set_edit_data(data.item)"
                                    size="sm"
                                    class="mr-3"
                                    variant="outline-success shadow">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </b-button>
                                <b-button
                                    v-b-modal.confirm_delete_modal
                                    @click="training_id = data.item.id"
                                    size="sm"
                                    variant="outline-danger shadow">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-table>
                 <p v-if="trainings.length === 0" class="mt-5">Trenutno nema treninga</p>
             </b-col>
        </b-row>
        <b-modal
            id="new_training_modal"
            ref="new_training_modal"
            scrollable
            @hide="form = {}; clearImage(null);"
            title="Kreiraj novi trening"
            size="lg"
            centered
        >
            <b-container>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group label="Naslov [SRB]">
                                <b-form-input
                                    required
                                    v-model="form.name"
                                    placeholder="Unesite naslov treninga (obavezno)"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Naslov [ENG]">
                                <b-form-input
                                    required
                                    v-model="form.name_eng"
                                    placeholder="Unesite naslov na engleskom (obavezno)"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Opis [SRB]">
                                <vue-editor
                                    v-model="form.description"
                                    placeholder="Unesite opis treninga (obavezno)"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Opis [ENG]">
                                <vue-editor
                                    v-model="form.description_eng"
                                    placeholder="Unesite opis treninga na engleskom jeziku (obavezno)"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group label="Slika">
                        <div v-if="image.url" class="text-center mb-3 bg-primary p-3 rounded">
                             <b-img :src="image.url" v-bind="image_size" class="img-thumbnail"/>
                        </div>
                        <b-form-file
                            accept="image/*"
                            type="file"
                            @change="setImage"
                            placeholder="Izaberite sliku (obavezno)">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  500 x 500</small>
                    </b-form-group>
                    <b-row>
                        <b-col>
                            <b-form-group label="Trajanje">
                                <b-form-input
                                    required
                                    type="number"
                                    v-model="form.duration"
                                    placeholder="Unesite trajanje treninga (obavezno)"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Intenzitet">
                                <b-form-select
                                    v-model="form.intensity"
                                    :options="intensityOptions"
                                    value-field="value"
                                    text-field="text">
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Kalorije">
                                <b-form-input
                                    required
                                    type="number"
                                    v-model="form.calories"
                                    placeholder="Unesite potrošnju kalorija (obavezno)"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Tip treninga">
                                <b-form-select
                                    v-model="form.training_type"
                                    :options="training_types"
                                    value-field="value"
                                    text-field="text">
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('new_training_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="$v.form.$invalid || !image.image || loading"
                    @click="addNew(form)">
                    <b-spinner v-if="loading" small class="mr-1"></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodaj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_training_modal"
            ref="edit_training_modal"
            scrollable
            size="lg"
            centered
            title="Izmeni trening"
            @hide="form = {}; clearImage(null);"
        >
            <b-container>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group label="Naslov [SRB]">
                                <b-form-input
                                    required
                                    v-model="edit_data.name"
                                    placeholder="Unesite naslov treninga"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Naslov [ENG]">
                                <b-form-input
                                    required
                                    v-model="edit_data.name_eng"
                                    placeholder="Unesite naslov treninga na engleskom"
                                ></b-form-input>

                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <small class="text-danger d-flex justify-content-center">Ako postoji jedan naslov za oba jezika, prosledite isti naslov u oba polja</small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Opis [SRB]">
                                <vue-editor
                                    v-model="edit_data.description"
                                    placeholder="Unesite opis treninga na engleskom jeziku"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Opis [ENG]">
                                <vue-editor
                                    v-model="edit_data.description_eng"
                                    placeholder="Unesite opis treninga na engleskom jeziku"
                                    :editorToolbar="customToolbar"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group>
                        <b-form-group label="Slika">
                             <div v-if="image.url" class="text-center mb-3 bg-primary p-2 rounded">
                                 <b-img :src="image.url" v-bind="image_size" class="img-thumbnail"/>
                             </div>
                            <b-form-file
                                accept="image/*"
                                type="file"
                                @change="setImage"
                                placeholder="Izaberite sliku">
                            </b-form-file>
                            <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  500 x 500</small>
                        </b-form-group>
                    </b-form-group>
                    <b-row>
                        <b-col>
                            <b-form-group label="Trajanje">
                                <b-form-input
                                    required
                                    type="number"
                                    v-model="edit_data.duration"
                                    placeholder="Unesite trajanje treninga"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Intenzitet">
                                <b-form-select
                                    v-model="edit_data.intensity"
                                    :options="intensityOptions"
                                    value-field="value"
                                    text-field="text">
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Kalorije">
                                <b-form-input
                                    required
                                    type="number"
                                    v-model="edit_data.calories"
                                    placeholder="Unesite potrošnju kalorija"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Tip treninga">
                                <b-form-select
                                    v-model="edit_data.training_type"
                                    :options="training_types"
                                    value-field="value"
                                    text-field="text">
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_training_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="$v.edit_data.$invalid || loading"
                    @click="editTraining(edit_data)">
                    <b-spinner v-if="loading" small class="mr-1"></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <confirm-delete-modal
            :modal-name="'confirm_delete_modal'"
            :description="'trening'"
            @confirm="deleteTraining(training_id)"/>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    name: "Trainings",
    components: {ConfirmDeleteModal},
    data() {
        return {
            host: process.env.VUE_APP_API_URL,
            form: {
                intensity: null,
                training_type: null,
            },
            image_size: { width: 200, height: 200 },
            customToolbar: [["bold", "italic", "underline"]],
            edit_data: {},
            training_id: null,
            training_types: [
                { value: null, text: 'Izaberite tip treninga', disabled: true },
                { value: 'freestyle', text: 'Freestyle' },
                { value: 'lesmills', text: 'Lesmills' },
            ],
            fields: [
                { key: "name", label: "Naziv", class: "text-left" },
                { key: "actions", label: "Akcije", class: "text-right"},
            ],
            intensityOptions: [
              { value: null, text: 'Izaberite intenzitet', disabled: true },
              { value: 1, text: '1' },
              { value: 2, text: '2' },
              { value: 3, text: '3' },
              { value: 4, text: '4' },
              { value: 5, text: '5' },
            ],

        }
    },
    validationGroup: ['form', 'edit_data'],
    validations: {
        form: {
            name: {
                required,
            },
            name_eng: {
                required,
            },
            description: {
                required,
            },
            description_eng: {
                required,
            },
            duration: {
                required,
            },
            intensity: {
                required,
            },
            calories: {
                required,
            },
            training_type: {
                required,
            }
        },
        edit_data: {
            name: {
                required,
            },
            name_eng: {
                required,
            },
            description: {
                required,
            },
            description_eng: {
                required,
            },
            duration: {
                required,
            },
            intensity: {
                required,
            },
            calories: {
                required,
            },
            training_type: {
                required,
            }
        },
    },
    mounted() {
        if (!this.trainings || this.trainings.length < 1) {
            this.fetchTrainings();
        }
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("trainings", {
            trainings: "getTrainings"
        }),
        ...mapGetters("upload", {
            image: "getImage_1"
        }),
    },
    methods: {
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapMutations("upload", {
            setImage: "setImage_1",
            clearImage: "clearImages",
            setEditImage: "setEditData"
        }),
        ...mapActions("trainings", {
            fetchTrainings: "fetchTrainings",
            addNew: "addNew",
            editTraining: "editTraining",
            deleteTraining: "deleteTraining"
        }),
        set_edit_data(data) {
            this.training_id = data.id;
            this.edit_data = data;
            this.setEditImage({
                url_1: String(this.host) + '/' + String(data.photo_url),
            });
        }
    }
}
</script>