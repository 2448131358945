<template>
    <div class="border mt-4 px-2 mx-0 ml-64">
        <b-row class="mb-4 mt-4">
            <b-col class="text-left">
                <b-button
                    variant="outline-dark shadow"
                    v-b-modal.new_event_modal
                    @click="modal_button_disabled = false">
                    <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>
                    Dodaj aktuelnost
                </b-button>
            </b-col>
        </b-row>
        <b-row>
             <b-col>
                <b-table
                   :items="events"
                   :fields="fields"
                   :tbody-tr-class="rowClass"
                   hover
                   :busy="loading"
                   responsive
                   head-variant="dark"
                   :striped="true"
                
                >
                    <template v-slot:cell(start_date)="data">
                        {{ data.item.start_date | moment('DD.MM.YYYY') }}
                    </template>
                     <template v-slot:cell(end_date)="data">
                         {{ data.item.end_date | moment('DD.MM.YYYY') }}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            v-b-modal.edit_event_modal
                            @click="set_edit_data(data.item)"
                            size="sm"
                            class="mr-3"
                            variant="outline-success shadow">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </b-button>
                        <b-button
                            v-b-modal.confirm_delete_modal
                            @click="event_id = data.item.id"
                            size="sm"
                            variant="outline-danger shadow">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </b-button>
                    </template>
                </b-table>
                 <p v-if="events.length === 0" class="mt-5">Trenutno nema aktuelnosti</p>
             </b-col>
        </b-row>
        <b-modal
            id="new_event_modal"
            ref="new_event_modal"
            scrollable
            title="Dodaj novu akutelnost"
            @hide="form = {}; clearImages(null);"
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-form-input
                            required
                            v-model="form.header"
                            :class="!$v.form.header.required ? 'border-danger' : ''"
                            placeholder="Unesite naslov aktuelnosti"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            required
                            v-model="form.header_eng"
                            :class="!$v.form.header_eng.required ? 'border-danger' : ''"
                            placeholder="Unesite naslov aktuelnosti na engleskom"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <vue-editor
                            required
                            v-model="form.text"
                            class="text-editor"
                            placeholder="Unesite tekst aktuelnosti na srpskom (Obavezno)"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group>
                        <vue-editor
                            required
                            class="text-editor"
                            v-model="form.text_eng "
                            placeholder="Unesite tekst aktuelnosti na engleskom (Obavezno)"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="image_1.url" class="text-center bg-primary rounded p-3 mb-2">
                            <b-img :src="image_1.url" v-bind="image_size" class="img-thumbnail"/>
                        </div>
                        <b-form-file
                            required
                            accept="image/*"
                            :class="!$v.form.text.required ? 'border-danger' : ''"
                            type="file"
                            @change="setImage_1"
                            placeholder="Izaberite sliku za prikaz aktuelnosti (obavezno)">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  500 x 500</small>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="image_2.url" class="bg-primary p-3 mb-2">
                            <img :src="image_2.url" class="img-thumbnail" />
                        </div>
                        <b-form-file
                            required
                            accept="image/*"
                            type="file"
                            @change="setImage_2"
                            placeholder="Izaberite sliku za Home Page (obavezno)">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  2000 x 500</small>
                    </b-form-group>
                    <b-form-group>
                        <date-time-picker
                            v-model="form.start_date"
                            only-date
                            color="#787c80"
                            button-color="#787c80"
                            overlay
                            format="YYYY-MM-DD"
                            label="Izaberite datum pocetka aktuelnosti (obavezno)"
                            button-now-translation="Današnji datum"
                            left
                            light
                            no-header
                            no-shortcuts
                            auto-close
                            :max-date="maxDate"
                        ></date-time-picker>
                    </b-form-group>
                     <b-form-group>
                        <date-time-picker
                            v-model="form.end_date"
                            only-date
                            color="#787c80"
                            button-color="#787c80"
                            overlay
                            format="YYYY-MM-DD"
                            label="Izaberite datum zavrsetka aktuelnosti (obavezno)"
                            button-now-translation="Današnji datum"
                            left
                            light
                            no-header
                            no-shortcuts
                            auto-close
                            :max-date="maxDate"
                        ></date-time-picker>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('new_event_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.form.$invalid"
                    @click="addNew(form)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Dodaj
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit_event_modal"
            ref="edit_event_modal"
            title="Izmeni akutelnost"
            scrollable
        >
            <b-container>
                <b-form>
                    <b-form-group>
                        <b-form-input
                            required
                            v-model="edit_data.header"
                            :class="!$v.edit_data.header.required ? 'border-danger' : ''"
                            placeholder="Unesite naslov aktuelnosti"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                            required
                            v-model="edit_data.header_eng"
                            :class="!$v.edit_data.header_eng.required ? 'border-danger' : ''"
                            placeholder="Unesite naslov aktuelnosti na engleskom"
                        ></b-form-input>
                        <small class="text-danger">Ako postoji jedan naslov za oba jezika, prosledite isti naslov u oba polja</small>
                    </b-form-group>
                    <b-form-group>
                        <vue-editor
                            v-model="edit_data.text"
                            class="text-editor"
                            placeholder="Unesite tekst aktuelnosti na srpskom"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group>
                        <vue-editor
                            v-model="edit_data.text_eng"
                            class="text-editor"
                            placeholder="Unesite tekst aktuelnosti na engleskom"
                            :editorToolbar="customToolbar"
                        ></vue-editor>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="image_1.url" class="text-center bg-primary p-3 rounded mb-2">
                            <b-img :src="image_1.url" v-bind="image_size" class="img-thumbnail"/>
                        </div>
                        <b-form-file
                            required
                            accept="image/*"
                            type="file"
                            @change="setImage_1"
                            placeholder="Izaberite sliku za prikaz aktuelnosti">
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  500 x 500</small>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="image_2.url" class="bg-primary p-3 rounded mb-2">
                            <img :src="image_2.url" class="img-thumbnail" />
                        </div>
                        <b-form-file
                            required
                            accept="image/*"
                            type="file"
                            @change="setImage_2"
                            placeholder="Izaberite sliku za Home Page"
                            >
                        </b-form-file>
                        <small class="text-danger mt-1">Preporučene dimenzije za upload fotografije su  2000 x 500</small>
                    </b-form-group>
                    <b-form-group>
                        <date-time-picker
                            v-model="edit_data.start_date"
                            only-date
                            color="#787c80"
                            button-color="#787c80"
                            overlay
                            format="YYYY-MM-DD"
                            label="Izaberite datum pocetka aktuelnosti"
                            button-now-translation="Današnji datum"
                            left
                            light
                            no-header
                            no-shortcuts
                            auto-close
                            :max-date="maxDate"
                        ></date-time-picker>
                    </b-form-group>
                     <b-form-group>
                        <date-time-picker
                            v-model="edit_data.end_date"
                            only-date
                            color="#787c80"
                            button-color="#787c80"
                            overlay
                            format="YYYY-MM-DD"
                            label="Izaberite datum zavrsetka aktuelnosti"
                            button-now-translation="Današnji datum"
                            left
                            light
                            no-header
                            no-shortcuts
                            auto-close
                            :max-date="maxDate"
                        ></date-time-picker>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button @click="$bvModal.hide('edit_event_modal')" size="sm" variant="outline-danger">
                    <i class="fa fa-window-close mr-1" aria-hidden="true"></i>
                    Otkaži
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="loading || $v.edit_data.$invalid"
                    @click="editEvent(edit_data)">
                    <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                    <i v-if="!loading" class="fa fa-check-square mr-1" aria-hidden="true"></i>
                    Izmeni
                </b-button>
            </template>
        </b-modal>

        <confirm-delete-modal
            :modal-name="'confirm_delete_modal'"
            :description="'aktuelnost'"
            @confirm="deleteEvent(event_id)"/>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "Event",
    data() {
        return {
            url:null,
            url_home:null,
            host: process.env.VUE_APP_API_URL,
            image_size: { width: 200, height: 200 },
            maxDate: null,
            form: {},
            customToolbar: [["bold", "italic", "underline", "link"]],
            edit_data: {},
            event_id: null,
            modal_button_disabled : false,
            fields: [
                { key: "header", label: "Naslov (Srb)", class: "text-left" },
                { key: "start_date", label: "Datum pocetka", class: "d-none d-sm-none d-md-table-cell d-lg-table-cell"},
                { key: "end_date", label: "Datum kraja", class: "d-none d-sm-none d-md-table-cell d-lg-table-cell" },
                { key: "actions", label: "Akcije", class: "text-right" },
            ],
            err: {
                response: {
                    data: {
                        error: ''
                    }
                }
            }
        }
    },
    validationGroup: ['form', 'edit_data'],
    validations: {
        form: {
            header: {
                required,
            },
            header_eng: {
                required,
            },
            text: {
                required,
            },
            text_eng: {
                required,
            },
            start_date: {
                required,
            },
            end_date: {
                required,
            },
        },
        edit_data: {
            header: {
                required,
            },
            header_eng: {
                required,
            },
            text: {
                required,
            },
            text_eng: {
                required,
            },
            start_date: {
                required,
            },
            end_date: {
                required,
            },
        },
    },
    components: {ConfirmDeleteModal},
    mounted() {
        this.fetchEvents();
    },
    computed: {
        ...mapGetters("loader", {
            loading: "getLoading"
        }),
        ...mapGetters("events", {
            events: "getEvents"
        }),
        ...mapGetters("upload", {
            image_1: "getImage_1",
            image_2: "getImage_2",
        })
    },
    methods: {
        ...mapMutations("loader", {
            setLoading: "setLoading"
        }),
        ...mapMutations("upload", {
            setImage_1: "setImage_1",
            setImage_2: "setImage_2",
            setEditImage: "setEditData",
            clearImages: "clearImages",
        }),
        ...mapActions("events", {
            fetchEvents: "fetchEvents",
            addNew: "addNew",
            editEvent: "editEvent",
            deleteEvent: "deleteEvent",
        }),
        set_edit_data(data) {
            this.event_id = data.id;
            this.edit_data = data;
            this.setEditImage({
                url_1: String(this.host) + '/' + String(data.photo_url),
                url_2: String(this.host) + '/' + String(data.home_photo_url)
            });

        },
        getTodayDate() {
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            this.maxDate = year + '-' + month + '-' + day;
        },
        rowClass(item, type) {
            if (item && type === 'row') {
                var start_date = item.start_date;
                var end_date = item.end_date;
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;
                if (today <= end_date && today >= start_date ) {
                    return 'table-success';
                } else {
                    return 'table-danger';
                }
            }
        },
    },
}
</script>

<style>

</style>