<template>
    <b-container fluid class="border mt-4 w-100 mx-0 pr-1 pl-4 mb-5 pb-5">
        <b-row class="bg-dark mx-0 mb-1 mt-3 w-100 rounded-sm">
            <b-col
                class="text-light font-weight-bold py-3 hover rounded-sm"
                :class="{ activeGym: i === activeItem}"
                v-for="(gym, i) in gyms"
                :key="i"
                style="cursor:pointer;"
                @click="selectGymNavItem(i, gym); selectedGym = gym;"
                v-if="gym.id !== null && gym.fitness">
                {{ gym.name }}
            </b-col>
        </b-row>
        <b-row v-if="tabIndex === 0" class="bg-dark mx-0 mb-1 mt-3 w-100 rounded-sm">
            <b-col
                class="text-light font-weight-bold py-3 hover rounded-sm"
                :class="{ activeGym: i === activeRoomItem}"
                v-for="(room, i) in rooms"
                :key="i"
                style="cursor:pointer;"
                @click="selectRoomNavItem(i, room)">
                {{ room.name }}
            </b-col>
        </b-row>
        <b-row>   
            <b-col v-if="tabIndex === 0" class="p-0 m-0 mt-3 col-sm-6 col-md-2">
                <small
                    v-if="!startDate && tabIndex === 0"
                    class="text-info d-flex justify-content-start col-12">Datum je obavezan</small>
                <date-time-picker
                    v-model="startDateWeekly"
                    only-date
                    id="1"
                    color="#787c80"
                    button-color="#787c80"
                    class="col-12"
                    no-button
                    overlay
                    format="YYYY-MM-DD"
                    label="Početni datum"
                    button-now-translation="Današnji datum"
                    left
                    :disabled-weekly="tabIndex === 0 ? [0, 2, 3, 4, 5, 6] : []"
                    :first-day-of-week=1
                    light
                    no-header
                    no-shortcuts
                    auto-close
                ></date-time-picker>
            </b-col>
            <b-col v-if="tabIndex === 1" class="p-0 m-0 mt-3 col-sm-3 col-md-2">
                <date-time-picker
                    v-model="startDate"
                    only-date
                    color="#787c80"
                    id="2"
                    button-color="#787c80"
                    class="col-12"
                    no-button
                    overlay
                    format="YYYY-MM-DD"
                    label="Početni datum"
                    button-now-translation="Današnji datum"
                    left
                    :disabled-weekly="tabIndex === 0 ? [0, 2, 3, 4, 5, 6] : []"
                    :first-day-of-week=1
                    light
                    no-header
                    no-shortcuts
                    auto-close
                ></date-time-picker>
            </b-col>
            <b-col v-if="tabIndex === 1" class="p-0 m-0 mt-3 ml-3 col-sm-3 col-md-2">
                <date-time-picker
                    v-model="endDate"
                    only-date
                    id="3"
                    color="#787c80"
                    button-color="#787c80"
                    overlay
                    format="YYYY-MM-DD"
                    label="Krajnji datum"
                    :disabled="!startDate"
                    button-now-translation="Današnji datum"
                    left
                    :first-day-of-week=1
                    light
                    no-header
                    no-shortcuts
                    auto-close
                ></date-time-picker>
            </b-col>
            <b-col v-if="tabIndex === 1" class="p-0 m-0 mt-3 ml-2 col-sm-2 col-md-2">
                <b-button
                    variant="outline-dark"
                    @click="fetchMonthlyReport({startDate: startDate, endDate: endDate, selectedGym: selectedGym})"
                    class="w-100 ml-4 py-2"><i class="fa fa-sync-alt mr-2" aria-hidden="true"></i>Obradi izveštaj
                </b-button>
            </b-col>
            <b-col v-if="tabIndex === 0" class="ml-auto mt-3 mr-3 border border-right-0 col-sm-12 col-md-4">
                        <b-row cols="12">
                            <b-col cols="3">
                                <b-row cols="12" class="mb-2">
                                    <b-col cols="8" class="bg-danger py-2 mt-2 ml-auto"></b-col>
                                </b-row>
                                <b-row cols="12">
                                    <b-col cols="8" class="bg-info py-2 ml-auto"></b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="9" class="pb-1">
                                <b-row cols="12" class="mb-0">
                                    <b-col cols="12" class="text-left mt-1">- Trener je isplaćen</b-col>
                                </b-row>
                                <b-row cols="12">
                                    <b-col cols="12" class="text-left">- Trener je probio termin</b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
        </b-row>
        <b-tabs v-model="tabIndex" content-class="mt-2" class="mt-4 bg-light">
            <b-tab title="Sedmični izveštaj" class="bg-light">
                <div class="table-responsive">
                    <table v-if="Object.keys(sortedWeeklyReport).length > 0 && !loading" class="table table-light border shadow">
                        <tr class="bg-primary">
                            <th class="border border-secondary">
                                <b-spinner v-if="loading" class="pt-2 mr-2"></b-spinner>
                            </th>
                            <th v-for="(date, i) in sortedWeeklyReport" class="border border-secondary">
                                <span class="d-block">{{ date[0].day | daysFilter}}</span>
                                <span>{{ i | moment('DD.MM.YYYY') }}</span>
                            </th>
                        </tr>
                        <tr class="border" v-for="(hour, i) in hours">
                            <td class="border border-secondary p-0 pl-1 text-left">
                                <div class="border-bottom">
                                    <div>Vreme</div>
                                    <div>i program</div>
                                </div>
                                <div class="py-1">Broj vežbača</div>
                                <div class="border-top">Ime i prezime</div>
                            </td>
                            <td
                                class="border border-secondary m-0 p-0"
                                v-for="(report, date) in sortedWeeklyReport">
                                <div v-for="(rep, index) in report" v-if="rep && Number(rep.time) === Number(i)">
                                    <div
                                        :class="rep.finished_on_time ? 'bg-info text-light' : ''"
                                        class="border-bottom text-uppercase">
                                        <small class="font-small">
                                            {{ rep.time | formatTime }}
                                        </small><br>
                                        <small class="font-weight-bolder">{{ rep.name }}</small>
                                    </div>
                                    <div class="py-1"><span class="border rounded-circle p-1 px-2 shadow number-size">{{ rep.booking_count }}</span></div>
                                    <div
                                        :class="rep.paid ? 'bg-danger text-light' : ''"
                                        class="border-top text-uppercase">
                                        <small>
                                            {{ rep.coach ? rep.coach : 'Trener nije upisan'}}
                                            {{ rep.coach_sub ? '(Z)' : '' }}
                                        </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="Object.keys(weeklyReport).length === 0">
                    <p class="my-5 py-5">Nema rezultata za izabrane datume.</p>
                </div>
            </b-tab>

            <b-tab title="Mesečni izveštaj" class="bg-light">
                <div v-for="n in 7" class="table-responsive pt-3 bg-light shadow-sm">
                    <table v-if="Object.keys(monthlyReport).length > 0 && !loading" class="table table-light border shadow">
                        <tr v-if="!loading" class="bg-primary">
                            <th class="border text-left">
                                {{ n | daysFilter }}
                            </th>
                            <th v-for="(date, i) in dates" class="border" v-if="Number(date.day) === Number(n)">
                                {{ date.date | moment('DD.MM.YYYY') }}
                            </th>
                        </tr>
                        <tr class="border" v-for="(report, i) in monthlyReport" v-if="report.days.includes(n)">
                            <td class="border p-0 pt-2 text-left px-2">{{ report.name }}</td>
                            <td class="border m-0 p-0 flex" v-for="date in dates" v-if="Number(date.day) === Number(n)">
                                <div class="m-auto" v-for="(rep, ind) in report.value" v-if="rep.training_date === date.date">
                                    {{ rep.booking_count }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="Object.keys(monthlyReport).length === 0">
                    <p class="my-5 py-5">Nema rezultata za izabrane datume ili dan ({{ n | daysFilter }}).</p>
                </div>
            </b-tab>
        </b-tabs>
    </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
    export default {
        name: "Statistic",
        data() {
            return {
                tabIndex: 0,
                activeItem: 0,
                activeRoomItem: 0,
                startDateWeekly: null,
                startDate: null,
                endDate: null,
                n: 0,
                maxDate: null,
                day: null,
                currentDay: 1,
                trainings: {},
                selectedGym: null,
                selectedRoom: null,
                days: [
                    {value: null, name: 'Izaberite dan'},
                    {value: 1, name: 'Ponedeljak'},
                    {value: 2, name: 'Utorak'},
                    {value: 3, name: 'Sreda'},
                    {value: 4, name: 'Četvrtak'},
                    {value: 5, name: 'Petak'},
                    {value: 6, name: 'Subota'},
                    {value: 7, name: 'Nedelja'},
                ],
            }
        },
        mounted() {
            // if (!this.gyms || this.gyms.length < 1) {
            //     this.fetchGyms();
            // } else {
            //     this.selectedGym = this.gyms[0];
            //     this.fetchRoomsForGym(this.gyms[0].id);
            //     this.selectedRoom = this.rooms[0];
            // }
			this.getTodayDate();
            this.fetchGyms();
            this.selectedGym = this.gyms[0];
            this.fetchRoomsForGym(this.gyms[0].id);
            this.selectedRoom = this.rooms[0];
        },
        destroyed() {
            this.selectedGym = null;
            this.selectedRoom = null;
            this.tabIndex = null;
			this.setGymRooms([]);
        },
        computed: {
            ...mapGetters("gyms", {
                gyms: "getGyms",
                rooms: "getGymRooms",
            }),
            ...mapGetters("loader", {
                loading: "getLoading"
            }),
            ...mapGetters("statistic", {
                weeklyReport: "getWeeklyReport",
                hours: "getHours",
                weeklyDates: "getWeeklyDates",
                dates: "getDates",
                monthlyReport: "getMonthlyReport",
            }),
			sortedWeeklyReport(){
				return Object.fromEntries(Object.entries(this.weeklyReport).sort ((a, b) => (a[0] > b[0] ) ? 1 : -1));
			}
        },
        methods: {
            ...mapActions("statistic", {
                fetchWeeklyReport: "fetchWeeklyReport",
                fetchMonthlyReport: "fetchMonthlyReport",
            }),
            ...mapActions("gyms", {
                fetchGyms: "fetchGyms",
                fetchRoomsForGym: "fetchRoomsForGym",
            }),
            ...mapMutations("loader", {
                setLoading: "setLoading"
            }),
			...mapMutations("gyms", {
                setGymRooms: "setGymRooms"
            }),
            async selectGymNavItem(i, gym) {
                this.activeItem = i;
                if (this.tabIndex === 0) {
                    await this.fetchRoomsForGym(gym.id)
                        .then((data) => {
                            this.selectedRoom = this.rooms[0];
                        })
                }

            },
            selectRoomNavItem(i, room) {
                this.activeRoomItem = i;
                this.fetchWeeklyReport({startDateWeekly: this.startDateWeekly, selectedRoom: room});
            },
            getTodayDate() {
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate() - 6;
				this.maxDate = year + '-' + month + '-' + day;
				this.getMonday();
			},
			getMonday(){
                var moment = require('moment');
                this.startDateWeekly = ( moment().startOf('isoweek').format("YYYY-MM-DD"));
			},
			arrayEquals(a, b) {
				return Array.isArray(a) &&
					Array.isArray(b) &&
					a.length === b.length &&
					a.every((val, index) => val === b[index]);
}
        },
        watch: {
            gyms(val) {
                if (val.length > 0) {
                    this.selectedGym = this.gyms[0];
                    this.fetchRoomsForGym(this.gyms[0].id);
                    this.selectedRoom = this.rooms[0];
                }
            },
            startDateWeekly(val, oldVal) {
                if (oldVal !== null) {
                    if (this.tabIndex === 0) {
                        this.fetchWeeklyReport({startDateWeekly: this.startDateWeekly, selectedRoom: this.selectedRoom});
                    }
                }
            },
            selectedGym(val, oldVal) {
                this.activeRoomItem = 0;
                if (oldVal !== null) {
                    this.fetchRoomsForGym(this.selectedGym.id);
                    this.selectedRoom = this.rooms[0];
                    if (this.tabIndex === 1) {
                        this.fetchMonthlyReport({startDate: this.startDate, endDate: this.endDate, selectedGym: this.selectedGym})
                    }
                } 
            },
            selectedRoom(val) {
                this.fetchWeeklyReport({startDateWeekly: this.startDateWeekly, selectedRoom: val});
            },
            tabIndex(val) {
                if (val === 0) {
                    this.fetchWeeklyReport({startDateWeekly: this.startDateWeekly, selectedRoom: this.selectedRoom});
                } else {
                    this.fetchMonthlyReport({startDate: this.startDate, endDate: this.endDate, selectedGym: this.selectedGym})
                }
            },
			rooms(val , oldVal) {
				if (!this.arrayEquals( oldVal , val )) {
					this.selectedRoom = val[0];
				}
			}
        }
    }
</script>