import http from "../config/http"
export default {
    namespaced: true,
    state: {
        blogs: [],
    },
    mutations: {
        setBlogs(state, data) {
            state.blogs = data;
        }
    },
    actions: {
        async fetchBlogs(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/blog');
            if (data) {
                context.commit('loader/setLoading', false, { root: true });
                data.sort((a, b) => (a.date_of_creation < b.date_of_creation ) ? 1 : -1);
                context.commit("setBlogs", data);
            }
        },
        async addNew(context, data) {
            const image = context.rootGetters["upload/getImage_1"];
            const apiData = new FormData();
            apiData.append('file', image.image);
            apiData.append('name', 'Kočović');
            apiData.append('header', data.header);
            apiData.append('header_eng', data.header_eng);
            apiData.append('text', data.text);
            apiData.append('text_eng', data.text_eng);

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            };


            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/blog', apiData, config)
                .then((resp) => {
                    context.dispatch("fetchBlogs");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE KREIRALI BLOG', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_blog_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_blog_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });

        },
        async editBlog(context, data) {
            const image = context.rootGetters["upload/getImage_1"];
            const apiData = new FormData();
            if(image.image) {
                apiData.append('file', image.image)
            }
            apiData.append('header', data.header);
            apiData.append('header_eng', data.header_eng);
            apiData.append('name', 'Kočović');
            apiData.append('text', data.text);
            apiData.append('text_eng', data.text_eng);

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            };

            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/blog/${data.id}`, apiData, config)
                .then((resp) => {
                    context.dispatch("fetchBlogs");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI BLOG', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_blog_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_blog_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });

        },
        async deleteBlog(context, blogId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/blog/${blogId}`)
                .then((resp) => {
                    context.dispatch("fetchBlogs");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI BLOG', { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                });
        },
    },
    getters: {
        getBlogs(state) {
          return state.blogs;
        },
    }
}