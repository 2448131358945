import http from "../config/http"
export default {
    namespaced: true,
    state: {
        trainings: [],
    },
    mutations: {
        setTrainings(state, data) {
          state.trainings = data;
        }
    },
    actions: {
        async fetchTrainings(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/bo/training');
            if (data) {
                context.commit('loader/setLoading', false, { root: true });
                data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ) ? 1 : -1);
                context.commit("setTrainings", data);
            }
        },
        async addNew(context, data) {
            const image = context.rootGetters["upload/getImage_1"];
            const apiData = new FormData();
            apiData.append('file', image.image);
            apiData.append('name', data.name);
            apiData.append('name_eng', data.name_eng);
            apiData.append('description', data.description);
            apiData.append('description_eng', data.description_eng);
            apiData.append('duration', data.duration);
            apiData.append('intensity', data.intensity);
            apiData.append('calories', data.calories);
            apiData.append('training_type', data.training_type);

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/training', apiData, config)
                .then((resp) => {
                    context.dispatch("fetchTrainings");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE DODALI TRENING', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_training_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'new_training_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });
        },
        async editTraining(context, data) {
            const image = context.rootGetters["upload/getImage_1"];
            const apiData = new FormData();
            if(image.image) {
                apiData.append('file', image.image)
            }
            apiData.append('name', data.name);
            apiData.append('description', data.description);
            apiData.append('description_eng', data.description_eng);
            apiData.append('duration', data.duration);
            apiData.append('intensity', data.intensity);
            apiData.append('calories', data.calories);
            apiData.append('training_type', data.training_type);

            let config = {
                  header : {
                    'Content-Type' : 'multipart/form-data'
                  }
             }

            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/training/${data.id}`, apiData, config)
                .then((resp) => {
                    context.dispatch("fetchTrainings");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI INFORMACIJE O TREININGU', { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_training_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_training_modal', show: false}, { root: true });
                    context.commit('upload/clearImages', null, {root: true});
                });
        },
        async deleteTraining(context, trainingsId) {
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(`/bo/training/${trainingsId}`)
                .then((resp) => {
                    context.dispatch("fetchTrainings");
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI TRENING', { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_delete_modal', show: false}, { root: true });
                });
        },
    },
    getters: {
        getTrainings(state) {
          return state.trainings;
        },
    }
}