import http from "../../config/http"
export default {
    namespaced: true,
    state: {
        codes: [],
        // allCodes: [],
        // copyOfAllCodes: [],
        codeCount: 0,
    },
    mutations: {
        setCodes(state, data) {
          state.codes = data;
        },
        setCodeCount(state, data) {
          state.codeCount = data;
        },
        // setAllCodes(state, data) {
        //   state.allCodes = data;
        // },
        // setCopyOfAllCodes(state, data) {
        //   state.copyOfAllCodes = data;
        // }
    },
    actions: {
        // CDUD for Codes
        // async fetchAllCodes(context) {
        //     const { data } = await http.get('/bo/code');
        //     if (data) {
        //         data.forEach((item, i) => {
        //             data[i]['codeAndName'] = item.name + ' | ' + item.code;
        //         })
        //         context.commit("setAllCodes", data);
        //         context.commit("setCopyOfAllCodes", data);
        //         // context.dispatch("updateCodeList");
        //         // this.updateCodeList();
        //     }
        // },
        async fetchCodes(context, data) {
            let url = '/bo/code/search/';
            if (data.searchString !== '') {
                url = `/bo/code/search/${data.searchString}`;
            }
            context.commit('loader/setLoading', true, { root: true });
            const resp = await http.get(url, { params: { ...data.filters } });
            if (resp) {
                context.commit('loader/setLoading', false, { root: true });
                resp.data.codes.forEach((item, i) => {
                    resp.data.codes[i]['codeAndName'] = item.name + ' | ' + item.code;
                });
                context.commit("setCodes", resp.data.codes);
                context.commit("setCodeCount", resp.data.total);
                context.dispatch("sortCodes", resp.data.codes);
            }
        },
        sortCodes(context, codes) {
            // new to go to the top and expired to the bottom
            const newList = [];
            var todaysDate = new Date();
            codes.forEach((item) => {
                var endDate = new Date(item.end_date);
                if (endDate > todaysDate) {
                    newList.push(item);
                }
            });
            codes.forEach((item) => {
                var endDate = new Date(item.end_date);
                if (endDate < todaysDate) {
                    newList.push(item);
                }
            });
            context.commit("setCodes", newList);
        },
        async createCode(context, data) {
            const apiData = {
                name: data.form.name,
                code: data.form.code,
                start_date: data.form.startDate,
                end_date: data.form.endDate,
            };
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/bo/code', apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE KREIRALI KOD', { root: true });
                    context.dispatch("fetchCodes", {searchString: data.searchString, filters: data.filters});
                    context.dispatch("groups/fetchAllCodes", "", {root: true});
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'create_code_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'create_code_modal', show: false}, { root: true });
                })
        },
        async editCode(context, data) {
            const apiData = {
                name: data.form.name,
                code: data.form.code,
                start_date: data.form.start_date,
                end_date: data.form.end_date,
            };
            context.commit('loader/setLoading', true, { root: true });
            await http.patch(`/bo/code/${data.form.id}`, apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZMENILI KOD', { root: true });
                    context.dispatch("fetchCodes", {searchString: data.searchString, filters: data.filters});
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_code_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setModal', {name: 'edit_code_modal', show: false}, { root: true });
                })
        },
        async deleteCode(context, data) {
            await http.delete(`/bo/code/${data.codeId}`)
                .then((resp) => {
                    console.log('delete iz vuexa')
                    context.commit('shared/setSuccessMessage', 'USPEŠNO STE IZBRISALI KOD', { root: true });
                    context.dispatch("fetchCodes", {searchString: data.searchString, filters: data.filters});
                    context.commit('shared/setModal', {name: 'delete_group_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('shared/setModal', {name: 'delete_code_modal', show: false}, { root: true });
                })
        },
    },
    getters: {
        // getAllCodes(state) {
        //   return state.allCodes;
        // },
        // getCopyOfAllCodes(state) {
        //   return state.allCodes;
        // },
        getCodes(state) {
          return state.codes;
        },
        getCodeCount(state) {
          return state.codeCount;
        },
    }
}