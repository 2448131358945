<template>
    <div>
        <app-schedule :page="'training_schedule'"></app-schedule>
    </div>
</template>

<script>
import AppSchedule from '../components/AppSchedule';
import {mapMutations} from "vuex";
export default {
    name: "Schedule",
    components: {AppSchedule},
    mounted() {
        this.setPage('training_schedule');
    },
    methods: {
        ...mapMutations("schedule", {
            setPage: "setPage"
        })
    }

}
</script>